import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { $ } from 'react-jquery-plugin';
import moment from "moment";
import Select from "react-select";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const SubAdmin = () => {
    const { SearchBar } = Search;
    const [subAdminList, setSubAdminList] = useState([]);
    const [subadminId, setSubadminId] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [gander, setGander] = useState('Male');
    const [dob, setDob] = useState('');
    const [multipleSelectd, setMultipleSelectd] = useState([]);
    const [adminType, setadminType] = useState();

    const [showModal, setshowModal] = useState(false);

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button className="btn btn-dark btn-sm me-2" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(row)}>Edit</button>
                <button className="btn btn-danger  btn-sm" onClick={() => deleteSubAdmin(row?._id)}>Delete</button>
            </>
        );
    };

    const statuslinkFollow = (cell, row, rowIndex, formatExtraData) => {
        
        return (
            <>
                <button class={row?.status === "Active" ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"} style={{ marginLeft: "20px" }} onClick={() => handleStatus(row?._id, row?.status === "Inactive" ? "Active" : "Inactive")}>{row?.status === "Active" ? "Active" : "Inactive"}</button>
            </>
        );
    };

    const dateFilter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {moment(row?.createdAt).format('MMMM Do YYYY')}
            </>
        );
    };

    const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {row?.first_name} {row?.last_name}
            </>
        );
    };

    const handleStatus = async (userId, cell) => {
        await AuthService.handleSubadminStatus2(userId, cell).then(async result => {
            if (result.success) {
                alertSuccessMessage(result.message);
                handleSubadmin();
            } else {
                alertErrorMessage(result.message)
            }
        })
    }

    const   handleSubadminDetail = (id) => {
        setshowModal(true)
        setFirstName(id.first_name);
        setLastName(id.last_name);
        setEmail(id.email_or_phone);
        setGander(id.gender);
        setDob(id.dob);
        setMultipleSelectd(id.permissions);
        setSubadminId(id._id);
        setadminType(id.admin_type);
    };

    const columns = [
        { dataField: 'first_name', text: 'Name', formatter: nameFormatter  },
        { dataField: 'email_or_phone', text: 'Email', sort: true, },
        { dataField: 'createdAt', text: 'Registration Date', sort: true, formatter: dateFilter },
        { dataField: 'active', text: 'Status', sort: true, formatter: statuslinkFollow },
        { dataField: 'Action', text: 'Action', formatter: linkFollow },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });

    useEffect(() => {
        handleSubadmin()
    }, []);

    const handleSubadmin = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getSubAdminList().then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setSubAdminList(result.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                /* alertErrorMessage("Something Went Wrong"); */
            }
        });
    }

    const deleteSubAdmin = async (userId) => {
        await AuthService.deleteSubAdminList(userId).then(async result => {
            if (result.success) {
                alertSuccessMessage("Delete Successfully");
                handleSubadmin()
            } else {
                alertErrorMessage(result.message)
            }
        })
    }

    const resetEditInput = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
        setPassword("");
        setGander("");
        setDob("");
    }

    const handleUpdateSubadminList = async (firstName, lastName, email, gander, subadminId, multipleSelectd,adminType) => {
        await AuthService.updateSubadminList(firstName, lastName, email, gander, subadminId, multipleSelectd,adminType).then(async result => {
            if (result.success) {
                try {
                    alertSuccessMessage("Subadmin Updated Successfully!!");
                    setshowModal(false)
                    $('#edit_modal').modal('hide');
                    resetEditInput();
                    handleSubadmin();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }


    var multipleSelect = [
        {
            value: 0,
            label: "Dashboards",
        },
        {
            value: 1,
            label: "Traders",
        },
        {
            value: 2,
            label: "KYC Manager",
        },
        {
            value: 3,
            label: "Currency Management",
        },
        {
            value: 4,
            label: "Exchange Wallet Management",
        },
        {
            value: 5,
            label: "Currency Pair Management",
        },
        {
            value: 6,
            label: "Admin Management",
        },
        {
            value: 7,
            label: "Package Management",
        },
        {
            value: 8,
            label: "Funds Management",
        },
        {
            value: 9,
            label: "Fiat Management",
        },
        {
            value: 10,
            label: "Notification Management",
        },
        {
            value: 11,
            label: 'Banner Management'
        },
        {
            value: 12,
            label: 'Commission Manager'
        },
        {
            value: 13,
            label: 'Market Trades'
        },
        {
            value: 14,
            label: 'Orderbook'
        },
        {
            value: 15,
            label: 'Staking List'
        },
       
    ];

    

    return (
        <>
            <div id="layoutSidenav_content">
                <main  style={{display:showModal ? 'none' :''}}>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="far fa-user"></i></div>
                                            Sub Admin List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* Main page content */}
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div class="card-header">Sub Admin Details
                                {subAdminList.length === 0 ? "" :
                                    <div class="dropdown">
                                        <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export </button>
                                        <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                            <CSVLink data={subAdminList} class="dropdown-item">Expoprt as CSV</CSVLink>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card-body mt-3">
                                <table className="" width="100%" >
                                    {subAdminList.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" /> <br /> No Data Available</h6> :
                                        <ToolkitProvider
                                            hover
                                            bootstrap4
                                            keyField='id'
                                            columns={columns}
                                            data={subAdminList}
                                            exportCSV
                                            search={{
                                                afterSearch: (newResult) => console.log(newResult)
                                            }}
                                        >
                                            {
                                                props => (
                                                    <React.Fragment>
                                                        <SearchBar {...props.searchProps} />
                                                        <BootstrapTable
                                                            hover
                                                            bootstrap4
                                                            keyField='id'
                                                            columns={columns}
                                                            data={subAdminList}
                                                            pagination={pagination}
                                                            filter={filterFactory()}
                                                            {...props.baseProps}
                                                        />
                                                    </React.Fragment>
                                                )
                                            }
                                        </ToolkitProvider>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            {/* sub admin edit Pair modal data */}
            <div class="modal"
             id="edit_modal" tabindex="-1" 
            style={{display:showModal ? 'block' :'none'}}
             role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-lg" role="document">
                    <div class="modal-content" style={{display:showModal ? 'block' :'none'}}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">
                                Edit Sub Admin Details
                            </h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setshowModal(false)}}> </button>
                        </div>
                        <div className="modal-body">

                            <form>
                                <div class="row gx-3 mb-3">
                                    <div class="col-md-6">  
                                        <label class="small mb-1" for="inputFirstName">First name <em>*</em></label>
                                        <input class="form-control  form-control-solid" id="inputFirstName" type="text" placeholder="Enter your first name" value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                                    </div>
                                    <div class="col-md-6">
                                        <label class="small mb-1" for="inputLastName">Last name <em>*</em> </label>
                                        <input class="form-control form-control-solid" id="inputLastName" type="text" placeholder="Enter your last name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
                                    </div>
                                    {/* <div class="col-md-4">
                                        <label class="small mb-1" for="inputBirthday">Gander <em>*</em></label>
                                        <select class="form-control form-control-solid" id="exampleFormControlSelect1" value={gander} onChange={(event) => setGander(event.target.value)} >
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div> */}
                                </div>
                                <div class="row gx-3 mb-3">
                                    <div class="col-md-12">
                                        <label class="small mb-1" for="inputEmailAddress">Email or Phone Number</label>
                                        <input class="form-control form-control-solid" id="inputEmailAddress" type="text" placeholder="Enter your email address" value={email} onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                    {/* <div class="col-md-6">
                                        <label class="small mb-1" for="inputLocation">Phone Number</label>
                                        <input class="form-control form-control-solid" id="inputLocation" type="text" placeholder="Enter your Number" value={phone} onChange={(event) => setPhone(event.target.value)} />
                                    </div> */}
                                </div>

                                <div class="row gx-3 mb-3">
                                    {/* <div class="col-md-6">
                                        <label class="small mb-1" for="inputLocation">Date of Birth</label>
                                        <input class="form-control form-control-solid" id="inputLocation" type="date" placeholder="Enter your Date of Birth" value={dob} onChange={(event) => setDob(event.target.value)} />
                                    </div> */}
                                    <div className="col-md-12" >
                                        <label className="small mb-1" for="inputLocation">Permissions</label>
                                        <Select isMulti options={multipleSelect}
                                            onChange={setMultipleSelectd}
                                            value={multipleSelectd}
                                        >
                                        </Select>
                                    </div>
                                </div>
                                <button class="btn btn-indigo" type="button" onClick={() => handleUpdateSubadminList(firstName, lastName, email, gander, subadminId, multipleSelectd,adminType)}> Submit Details </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* sub admin edit modal data */}
        </>

    )
}

export default SubAdmin;