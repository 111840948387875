import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { $ } from 'react-jquery-plugin';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ViewEmailTemplate from "../ViewEmailTemplate";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import './App.css';

const EmailTemplate = () => {


    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );



    const { SearchBar } = Search;
    const [activeScreen, setActiveScreen] = useState('viewTemplate');
    const [emailTaplateList, setEmailTaplateList] = useState([]);
    const [emailSubject, setEmailSubject] = useState('');
    const [key, setKey] = useState('');
    const [template, setTemplate] = useState('');
    const [userId, setUserId] = useState('');



    const statuslinkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button class={row?.active === "0" ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"} style={{ marginLeft: "20px" }} /* onClick={() => handleStatus(row?._id, cell === "0" ? "1" : "0")} */>{row?.active === "0" ? "Active" : "Inactive"}</button>
            </>
        );
    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button className="btn btn-dark btn-sm me-2" data-bs-toggle="modal" data-bs-target="#edit_modal" /* onClick={() => handleSubadminDetail(row)} */>Edit</button>
                <button className="btn btn-success btn-sm"
                    onClick={() => {
                        setUserId(row?.userId);
                        setActiveScreen('detail');
                    }} >View
                </button>
            </>
        );
    };


    const columns = [
        { dataField: 'subject', text: 'Email Subject', sort: true },
        { dataField: 'key', text: 'Key', sort: true, },
        { dataField: 'active', text: 'Status', sort: true, formatter: statuslinkFollow },
        { dataField: 'Action', text: 'Action', formatter: linkFollow },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 7,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });

    useEffect(() => {
        // handleEmailTamplateList()
    }, []);

    const handleEmailTamplateList = async () => {
        await AuthService.getEmailList().then(async result => {
            if (result.data.length > 0) {
                try {
                    setEmailTaplateList(result.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                /* alertErrorMessage("Something Went Wrong"); */
            }
        });
    }

    const resetEditInput = () => {
        setEmailSubject("");
        setKey("");
        setTemplate("");
    }

    const handleEmailTamplate = async (emailSubject, key, template) => {
        await AuthService.updateEmailTamplate(emailSubject, key, template).then(async result => {
            if (result.message === "") {
                try {
                    alertSuccessMessage(result.message);
                    $('#edit_modal').modal('hide');
                    resetEditInput();
                    handleEmailTamplateList();
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }

    console.log(editorState, 'template');

    return (
        activeScreen === 'viewTemplate' ?
            <>
                <div id="layoutSidenav_content">
                    <main>
                        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div className="container-xl px-4">
                                <div className="page-header-content pt-4">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto mt-4">
                                            <h1 className="page-header-title">
                                                <div className="page-header-icon"><i className="fa fa-envelope"></i></div>
                                                Email Template List
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="container-xl px-4 mt-n10">
                            <div className="card mb-4">
                                <div class="card-header">Sub Admin Details
                                    {emailTaplateList.length === 0 ? "" :
                                        <div class="dropdown">
                                            <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export </button>
                                            <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                <CSVLink data={emailTaplateList} class="dropdown-item">Export as CSV</CSVLink>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="card-body mt-3">
                                    <table className="" width="100%" >
                                        {emailTaplateList.length === 0 ? <h6 className="ifnoData"> <img src="assets/img/no-data.png" /> <br />No Data Available</h6> :
                                            <ToolkitProvider
                                                hover
                                                bootstrap4
                                                keyField='id'
                                                columns={columns}
                                                data={emailTaplateList}
                                                exportCSV
                                                search={{
                                                    afterSearch: (newResult) => console.log(newResult)
                                                }}
                                            >
                                                {
                                                    props => (
                                                        <React.Fragment>
                                                            <SearchBar {...props.searchProps} />
                                                            <BootstrapTable
                                                                hover
                                                                bootstrap4
                                                                keyField='id'
                                                                columns={columns}
                                                                data={emailTaplateList}
                                                                pagination={pagination}
                                                                filter={filterFactory()}
                                                                {...props.baseProps}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                }
                                            </ToolkitProvider>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

                {/* Edit Email Tamplate */}
                <div class="modal" id="edit_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog  alert_modal modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalCenterTitle">
                                    Edit Email Tamplate
                                </h5>
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div class="row gx-3 mb-3">
                                        <div class="col-md-12">
                                            <label class="small mb-1" for="inputFirstName">Email Subject <em>*</em></label>
                                            <input class="form-control  form-control-solid" id="inputFirstName" type="text" placeholder="Paid Contest" value={emailSubject} onChange={(event) => setEmailSubject(event.target.value)} />
                                        </div>
                                        <div class="col-md-12">
                                            <label class="small mb-1" for="inputkey">Key<em>*</em> </label>
                                            <input class="form-control form-control-solid" id="inputkey" type="text" placeholder="Paid Contest" value={key} onChange={(event) => setKey(event.target.value)} />
                                        </div>
                                        <div class="col-md-12 pt-2">
                                            <label class="small mb-1" for="inputBirthday">Template <em>*</em></label>
                                            <Editor
                                                editorState={editorState}
                                                onEditorStateChange={setEditorState}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                            />
                                        </div>
                                    </div>
                                    <button class="btn btn-indigo mt-3" type="button" onClick={() => handleEmailTamplate(emailSubject, key, editorState)}> Submit Details </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Edit Email Tamplate */}

            </>
            :
            <ViewEmailTemplate userId={userId} />
    )
}

export default EmailTemplate;