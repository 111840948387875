import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../../customComponent/Header";
import HomePage from "../HomePage";
import SubadminList from "../SubadminList";
import AddsubAdmin from "../AddsubAdmin";
import TradeList from "../TradeList";
import AddTrade from "../AddTrade";
import PendingKyc from "../PendingKyc";
import ApprovedKyc from "../ApprovedKyc";
import CurrencyManagement from "../CurrencyManagement";
import CurrencypairManagement from "../CurrencypairManagement";
import OrderManagement from "../OrderManagement";
import TradingReport from "../TradingReport";
import Notification from "../Notification";
import EmailTemplate from "../EmailTemplate";
import ContentManager from "../ContentManager";
import Setting from "../Setting";
import MasterWallet from "../MasterWallet";
import UserWalletManagement from "../UserWalletManagement";
import FundsManagement from "../FundsManagement";
import FiatManagement from "../FiatManagement";
import FundsDManagement from "../FundsDManagement";
import FiatDManagement from "../FiatDManagement";
import SupportPage from "../SupportPage";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import BannerManagement from "../BannerManagement";
import StakingDetails from "../StakingDetails";
import RejectedKyc from "../RejectedKyc";
import TradersBalance from "../TradersBalance";
import CommissionManager from "../CommissionManager";
import OrderBook from "../OrderBook";
import PackageManagement from "../PackageManagement";
import { Security } from "../Security";
import AddPackage from "../AddNewPackage";
import PriceManagement from "../PriceManagement";

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState("homepage");
  const [coinNameList, setCoinNameList] = useState([]);

  const myPermission = sessionStorage.getItem("permissions");
  console.log(myPermission, "oooo");
  const userType = sessionStorage.getItem("userType");
  // const userType = '1';
  const Permissions = myPermission ? JSON.parse(myPermission) : [];
  console.log(Permissions, "ppp");

  // const permissions = [1,2,3,4,5,6,7,8,9,10,11,12];
  const user_Id = sessionStorage.getItem("userId");

  const permissions = Permissions.map((item) => item.value);
  console.log(permissions, "LLL");

  useEffect(() => {
    // handleCoinTransfer();
    // handleGetreceive(user_Id);
  }, []);

  const handleCoinTransfer = async () => {
    await AuthService.coinTransfer().then(async (result) => {
      if (result?.success) {
        setCoinNameList(result?.data);
      } else {
        /*  alertErrorMessage(result.message); */
      }
    });
  };

  const handleGetreceive = async (coinName) => {
    await AuthService.getReceives(coinName, user_Id).then(async (result) => {
      console.log(result, "coinList");
      if (result.success) {
        try {
          // alertSuccessMessage(result.message);
        } catch (error) {
          // alertErrorMessage(error);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };

  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav className="sidenav shadow-right sidenav-light">
            <div className="sidenav-menu">
              <div className="nav accordion" id="accordionSidenav">
                <div className="sidenav-menu-heading">pages</div>

                <a
                  href="/dashboard"
                  className="nav-link collapsed"
                  onClick={() => setActiveTab("homepage")}
                >
                  <div className="nav-link-icon">
                    <i className="fa fa-th"></i>
                  </div>
                  Dashboards
                </a>

                {userType === "1" ? (
                  <>
                    <div
                      className="nav-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSubAdmin"
                      aria-expanded="false"
                      aria-controls="collapseSubAdmin"
                    >
                      <div className="nav-link-icon">
                        <i className="fa fa-user-friends"></i>
                      </div>
                      Sub Admin
                      <div className="sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      id="collapseSubAdmin"
                      data-bs-parent="#accordionSidenav"
                    >
                      <nav className="sidenav-menu-nested nav">
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("listsubadmin")}
                        >
                          Sub Admin List
                        </Link>
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("addsubadmin")}
                        >
                          Add New
                        </Link>
                      </nav>
                    </div>
                  </>
                ) : null}

                {permissions.includes(1) || userType === "1" ? (
                  <>
                    <div
                      className="nav-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTraders"
                      aria-expanded="false"
                      aria-controls="collapseTraders"
                    >
                      <div className="nav-link-icon">
                        <i className="fa fa-wave-square"></i>
                      </div>
                      Traders
                      <div className="sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </div>

                    <div
                      className="collapse"
                      id="collapseTraders"
                      data-bs-parent="#accordionSidenav"
                    >
                      <nav className="sidenav-menu-nested nav">
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("tradelist")}
                        >
                          Traders List
                        </Link>
                        {/* <Link className="nav-link" to="" onClick={() => setActiveTab('addnewtrade')}>Add New Trader </Link> */}
                      </nav>
                    </div>
                  </>
                ) : null}

                {permissions.includes(2) || userType === "1" ? (
                  <>
                    <div
                      className="nav-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseKyc"
                      aria-expanded="false"
                      aria-controls="collapseKyc"
                    >
                      <div className="nav-link-icon">
                        <i className="fa fa-check-circle"></i>
                      </div>
                      KYC Manager
                      <div className="sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </div>

                    <div
                      className="collapse"
                      id="collapseKyc"
                      data-bs-parent="#accordionSidenav"
                    >
                      <nav className="sidenav-menu-nested nav">
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("pendingkyc")}
                        >
                          Pending KYC
                        </Link>
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("approvedkyc")}
                        >
                          Approved KYC
                        </Link>
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("RejectedKyc")}
                        >
                          Rejected KYC
                        </Link>
                      </nav>
                    </div>
                  </>
                ) : null}

                {permissions.includes(3) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("currencymanagement")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-dollar-sign"></i>
                    </div>
                    Currency Management
                  </Link>
                ) : null}

                {permissions.includes(6) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("masterwallet")}
                  >
                    <div className="nav-link-icon">
                      <i class="fab fa-google-wallet"></i>
                    </div>
                    Admin Management
                  </Link>
                ) : null}

                {userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("security")}
                  >
                    <div className="nav-link-icon">
                      <i class="fab fa-google-wallet"></i>
                    </div>
                    Security
                  </Link>
                ) : null}

                {permissions.includes(4) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("tradersbalance")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-wallet"></i>
                    </div>
                    Exchange Wallet Management
                  </Link>
                ) : null}

                {permissions.includes(5) || userType === "1" ? (
                  <span
                    className="nav-link collapsed"
                    onClick={() => setActiveTab("currencypair")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-prescription"></i>
                    </div>
                    Currency Pair Management
                  </span>
                ) : null}

                {permissions.includes(5) || userType === "1" ? (
                  <span
                    className="nav-link collapsed"
                    onClick={() => setActiveTab("price")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-prescription"></i>
                    </div>
                    Price Management
                  </span>
                ) : null}

                {permissions.includes(7) || userType === "1" ? (
                  <>
                    <div
                      className="nav-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsePackageManagement"
                      aria-expanded="false"
                      aria-controls="collapsePackageManagement"
                    >
                      <div className="nav-link-icon">
                        <i className="fa fa-list"></i>
                      </div>
                      Package Management
                      <div className="sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      id="collapsePackageManagement"
                      data-bs-parent="#accordionSidenav"
                    >
                      <nav className="sidenav-menu-nested nav">
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("packageManagement")}
                        >
                          Package List
                        </Link>
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("addPackage")}
                        >
                          Add New Package
                        </Link>
                      </nav>
                    </div>
                  </>
                ) : null}

                {permissions.includes(12) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("commission_manager")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-list"></i>
                    </div>
                    Commission Manager
                  </Link>
                ) : null}

                {permissions.includes(8) || userType === "1" ? (
                  <>
                    <div
                      className="nav-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFundsManagement"
                      aria-expanded="false"
                      aria-controls="collapseSubAdmin"
                    >
                      <div className="nav-link-icon">
                        <i className="fa fa-dollar-sign"></i>
                      </div>
                      Funds Management
                      <div className="sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      id="collapseFundsManagement"
                      data-bs-parent="#accordionSidenav"
                    >
                      <nav className="sidenav-menu-nested nav">
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("fundsManagement")}
                        >
                          Withdrawal
                        </Link>
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("fundsDManagement")}
                        >
                          Deposit
                        </Link>
                      </nav>
                    </div>
                  </>
                ) : null}

                {permissions.includes(9) || userType === "1" ? (
                  <>
                    <div
                      className="nav-link collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiatManagement"
                      aria-expanded="false"
                      aria-controls="collapseSubAdmin"
                    >
                      <div className="nav-link-icon">
                        <i className="fa fa-feather"></i>
                      </div>
                      Fiat Management
                      <div className="sidenav-collapse-arrow">
                        <i className="fas fa-angle-down"></i>
                      </div>
                    </div>
                    <div
                      className="collapse"
                      id="collapseFiatManagement"
                      data-bs-parent="#accordionSidenav"
                    >
                      <nav className="sidenav-menu-nested nav">
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("fiatManagement")}
                        >
                          Withdrawal
                        </Link>
                        <Link
                          className="nav-link"
                          to=""
                          onClick={() => setActiveTab("fiatDManagement")}
                        >
                          Deposit
                        </Link>
                      </nav>
                    </div>
                  </>
                ) : null}

                {permissions.includes(13) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("tradingfeereport")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Market Trades
                  </Link>
                ) : null}
                {permissions.includes(14) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("OrderBook")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-list"></i>
                    </div>
                    OrderBook
                  </Link>
                ) : null}

                {permissions.includes(15) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("staking")}
                  >
                    <div className="nav-link-icon">
                      <i class="fa fa-list"></i>
                    </div>
                    Staking List
                  </Link>
                ) : (
                  ""
                )}

                {permissions.includes(10) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("notification")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-bell"></i>
                    </div>
                    Notifications Management
                  </Link>
                ) : null}

                {permissions.includes(11) || userType === "1" ? (
                  <Link
                    className="nav-link collapsed"
                    to=""
                    onClick={() => setActiveTab("bannerManagement")}
                  >
                    <div className="nav-link-icon">
                      <i className="fa fa-image"></i>
                    </div>
                    Banner Management
                  </Link>
                ) : null}
              </div>
            </div>
          </nav>
        </div>

        {activeTab === "homepage" && <HomePage />}
        {activeTab === "listsubadmin" && <SubadminList />}
        {activeTab === "addsubadmin" && <AddsubAdmin />}
        {activeTab === "tradelist" && <TradeList />}
        {activeTab === "addnewtrade" && <AddTrade />}
        {activeTab === "pendingkyc" && <PendingKyc />}
        {activeTab === "approvedkyc" && <ApprovedKyc />}
        {activeTab === "currencymanagement" && <CurrencyManagement />}
        {activeTab === "masterwallet" && (
          <MasterWallet userList={coinNameList} />
        )}
        {activeTab === "userwallet" && <UserWalletManagement />}
        {activeTab === "security" && <Security />}
        {activeTab === "currencypair" && <CurrencypairManagement />}
        {activeTab === "price" && <PriceManagement />}
        {activeTab === "order" && <OrderManagement />}
        {activeTab === "commission_manager" && <CommissionManager />}
        {activeTab === "tradersbalance" && <TradersBalance />}
        {activeTab === "fundsManagement" && <FundsManagement />}
        {activeTab === "fundsDManagement" && <FundsDManagement />}
        {activeTab === "fiatManagement" && <FiatManagement />}
        {activeTab === "fiatDManagement" && <FiatDManagement />}
        {activeTab === "tradingfeereport" && <TradingReport />}
        {activeTab === "OrderBook" && <OrderBook />}

        {activeTab === "notification" && <Notification />}
        {activeTab === "email" && <EmailTemplate />}
        {activeTab === "content" && <ContentManager />}
        {activeTab === "setting" && <Setting />}
        {activeTab === "staking" && <StakingDetails />}
        {activeTab === "packageManagement" && <PackageManagement />}
        {activeTab === "addPackage" && <AddPackage />}
        {activeTab === "bannerManagement" && <BannerManagement />}
        {activeTab === "RejectedKyc" && <RejectedKyc />}
      </div>
    </>
  );
};

export default DashboardPage;
