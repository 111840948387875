import React, { useState, useEffect } from "react";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { CSVLink } from "react-csv";
import moment from "moment";
import { $ } from "react-jquery-plugin";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const MasterWallet = () => {
  const [masterWalletList, setMasterWalletList] = useState([]);
  const [coinName, setCoinName] = useState("BNB");
  const [coinList, setCoinList] = useState([]);
  const [transList, setTransList] = useState([]);
  const [walletTo, setWalletTo] = useState("");
  const [sendWalletTo, setSendWalletTo] = useState("");
  const [walletFrom, setWalletFrom] = useState("");
  const [sendWalletFrom, setSendWalletFrom] = useState("");
  const [amount, setAmount] = useState("");
  const [walletCoinList, setWalletCoinList] = useState([]);
  const [admincoinAddress, setAdmincoinAddress] = useState("");
  const [SpotBalance, setSpotBalance] = useState([]);
  const [WalletBalance, setWalletBalance] = useState([]);
  const [bankName, setBankName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [holderName, setHolderName] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [branchName, setBranchName] = useState("");
  const [bankDetails, setBankDetails] = useState([]);
  const [reward, setReward] = useState("");
  const [rewardRate, setRewardRate] = useState("");
  const [tdsRate, setTdsRate] = useState("");
  const [tds, setTds] = useState("");
  const [id, setId] = useState("")

  const user_Id = sessionStorage.getItem("userId");

  useEffect(() => {
    //  handleMasterWalletList();
    // handleCoinList();
    // handleWalletCoinList();
    // handleAdmincoinaddress(coinName);
    // handleGetreceive(user_Id);
    // getRewardRate();
    // getTdsRate();
    handleBankAcc();
  }, []);
  const { SearchBar } = Search;

  const handleBankAccDetails = async (
    id,
    bankName,
    accNumber,
    holderName,
    ifsc,
    branchName
  ) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.addBankAccount(
      id,
      bankName,
      accNumber,
      holderName,
      ifsc,
      branchName
    ).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          alertSuccessMessage("Bank details updated successfully");
          setBankName("");
          setAccNumber("");
          setHolderName("");
          setIfsc("");
          setBranchName("");
          $("#bank_modal").modal("hide");    
          handleBankAcc()             
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  console.log(id, "pppp")

  const handleBankAcc = async () => {
    console.log("hellooo");
    // LoaderHelper.loaderStatus(true);
    await AuthService.getAccDetails().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setBankDetails(result?.data);
          setId(result.data[0]?._id)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };
 
  const handleMasterWalletList = async () => {
    await AuthService.getMasterWalletList(user_Id).then(async (result) => {
      if (Object.keys(result.data).length > 0) {
        try {
          // alertSuccessMessage(result.message);
          setMasterWalletList(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };

  // const handleCoinList = async () => {
  //     await AuthService.getCoinList().then(async result => {
  //         // console.log(result, "coinList");
  //         if (result.data.length > 0) {
  //             try {
  //                 // alertSuccessMessage(result.message);
  //                 setCoinList(result.data);
  //             } catch (error) {
  //                 alertErrorMessage(error);
  //             }
  //         } else {
  //             alertErrorMessage(result.message);
  //         }
  //     });
  // }

  const handleWalletCoinList = async () => {
    await AuthService.getwalletCoinList(user_Id).then(async (result) => {
      // console.log(result, "coinList");
      if (result.message === "Wallet Fetched Successfully!!") {
        try {
          // alertSuccessMessage(result.message);
          setWalletCoinList(result?.data?.wallet_addresses);
          handleAdmincoinaddress(coinName);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-dark"
          onClick={() => showModal(row)}
        >
          View Info.
        </button>
      </div>
    );
  };

  const linkFollow2 = (cell, row, rowIndex, formatExtraData) => {
    return <div>{moment(row?.createdAt).format("MMM Do YY")}</div>;
  };

  const columns = [
    { dataField: "date", text: "Date", formatter: linkFollow2 },
    { dataField: "coinSymbol", text: "Coin", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "transType", text: "Transaction Type", sort: true },
    { dataField: "txhash", text: "Transaction Hash", sort: true },
    { dataField: "status", text: "Status", sort: true },
    // { dataField: 'Action', text: 'Action', formatter: linkFollow },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const showModal = (item) => {
    // console.log(item, "item");
    setWalletFrom(item?.fromAdr);
    setWalletTo(item?.toAdr);
    $("#info_modal").modal("show");
  };

  const showTransfer = () => {
    $("#transfer_modal").modal("show");
  };

  const handleUserWalletTransfer = async (walletFrom, walletTo, amount) => {
    await AuthService.userWalletTransfer(
      walletFrom,
      walletTo,
      amount,
      coinName
    ).then(async (result) => {
      // console.log(result, "coinList");
      if (result.message == "Transfer Request submitted sucessfully") {
        try {
          // alertSuccessMessage(result.message);
          $("#transfer_modal").modal("hide");
          resetInputChange();
        } catch (error) {
          alertErrorMessage(error);
          console.log(error, "error");
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const resetInputChange = () => {
    setSendWalletFrom("");
    setSendWalletTo("");
    setAmount("");
  };

  const handleAdmincoinaddress = async (coinName) => {
    await AuthService.getAdmincoinaddress(coinName, user_Id).then(
      async (result) => {
        // console.log(result, "coinList");
        if (result.success) {
          try {
            // alertSuccessMessage(result.message);
            setAdmincoinAddress(result?.data?.address);
            setSpotBalance(result?.data?.spotBalance);
            setWalletBalance(result?.data?.walletBalance);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "reward":
        setReward(event.target.value);
        break;
      case "tdsRate":
        setTdsRate(event.target.value);
      default:
        break;
    }
  };

  const getRewardRate = async (reward) => {
    await AuthService.AddrewarRate(reward).then(async (result) => {
      if (result.sucess) {
        setRewardRate(result?.reward);
      }
    });
  };

  const handleRewardRate = async (reward) => {
    await AuthService.AddrewarRate(reward).then(async (result) => {
      if (result.sucess) {
        try {
          alertSuccessMessage("Successfully Updated Rewards Rate");
          getRewardRate();
          setReward("");
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.sucess);
      }
    });
  };

  const getTdsRate = async (tdsRate) => {
    await AuthService.AddtdsRate(tdsRate).then(async (result) => {
      if (result.success) {
        setTds(result?.success);
      }
    });
  };

  const handlTdsRate = async (tdsRate) => {
    await AuthService.AddtdsRate(tdsRate).then(async (result) => {
      if (result.success) {
        try {
          alertSuccessMessage("TDS Successfully Updated");
          setTdsRate("");
          getTdsRate();
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage("Already Updated");
      }
    });
  };

  const handleGetreceive = async (coinName) => {
    await AuthService.getReceives(coinName, user_Id).then(async (result) => {
      console.log(result, "coinList");
      if (result.success) {
        try {
          // alertSuccessMessage(result.message);
        } catch (error) {
          // alertErrorMessage(error);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-wallet"></i>
                      </div>
                      Admin Management
                    </h1>
                  </div>
                  {/* <div class="col-auto mt-4">
                    <button
                      class="btn btn-success btn-block w-100 mt-3"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#bank_modal"
                    >
                      Add Bank Account
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </header>
          {/* Main page content */}

          <div className="container-xl px-4 mt-n10">
            <div className="row">
              <div className="col-xl-8">
                <div className="row">
                  {/* <div className="col-md-6">
                    <div class="card mb-4">
                      <div className="card-body d-flex justify-content-center flex-column p-3 m-3 ">
                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                          <h5 className="mb-0">Set Rewards Rate (in INR)</h5>
                        </div>
                        <hr className="mt-0" />
                        <div className="w-100">
                          <div className="d-flex mb-2 align-items-center justify-content-between">
                            <strong>Current Reward Rate</strong>
                            <span>{rewardRate}</span>
                          </div>
                        </div>
                        <form>
                          <div class=" form-group mt-3">
                           
                            <input
                              class="form-control form-control-solid "
                              id="inputLocation"
                              type="number"
                              name="reward"
                              placeholder="Set Reward Rate"
                              value={reward}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="row mt-2">
                            <div className="col">
                              <button
                                class="btn btn-success btn-block w-100 mt-2"
                                type="button"
                                onClick={() => handleRewardRate(reward)}
                                disabled={!reward}
                              >
                                {" "}
                                Update{" "}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="col-md-6">
                    <div class="card mb-4">
                      <div className="card-body d-flex justify-content-center flex-column p-3 m-3 ">
                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                          <h5 className="mb-0">Set TDS%</h5>
                        </div>
                        <hr className="mt-0" />
                        <div className="w-100">
                          <div className="d-flex mb-2 align-items-center justify-content-between">
                            <strong>Current TDS Rate</strong>
                            <span>{tds}%</span>
                          </div>
                        </div>
                        <form>
                          <div class=" form-group mt-3">
                            <input
                              class="form-control form-control-solid "
                              id="inputLocation"
                              type="number"
                              name="tdsRate"
                              placeholder="Set Reward Rate"
                              value={tdsRate}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="row mt-2">
                            <div className="col">
                              <button
                                class="btn btn-success btn-block w-100 mt-2"
                                type="button"
                                onClick={() => handlTdsRate(tdsRate)}
                                disabled={!tdsRate}
                              >
                                {" "}
                                Update{" "}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-6">
                    <div class="card mb-4">
                      <div className="card-body d-flex justify-content-center flex-column p-3 m-3 ">
                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                          <h5 className="mb-0">Bank Account Details</h5>
                        </div>
                        <hr className="mt-0" />
                        {bankDetails.map((item) => (
                          <>
                            <div className="w-100">
                              <div className="d-flex mb-2 align-items-center justify-content-between">
                                <strong>Bank Name</strong>
                                <span>{item?.bank_name}</span>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="d-flex mb-2 align-items-center justify-content-between">
                                <strong>Account Number</strong>
                                <span>{item?.account_number}</span>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="d-flex mb-2 align-items-center justify-content-between">
                                <strong>Account Holder Name</strong>
                                <span>{item?.holder_name}</span>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="d-flex mb-2 align-items-center justify-content-between">
                                <strong>IFSC</strong>
                                <span>{item?.ifsc}</span>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="d-flex mb-2 align-items-center justify-content-between">
                                <strong>Branch Name</strong>
                                <span>{item?.branch}</span>
                              </div>
                            </div>
                          </>
                        ))}

                        <form>
                          <div className="row mt-2">
                            <div className="col">
                              <button
                                class="btn btn-success btn-block w-100 mt-2"
                                data-bs-toggle="modal"
                                data-bs-target="#bank_modal"
                                type="button"
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal"
            id="bank_modal"
            tabindex="-1"
            role="dialog"
            // aria-labelledby="transfer_modal_modalTitle"
            aria-hidden="true"
          >
            <div class="modal-dialog  alert_modal" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalCenterTitle">
                    Add Bank Account
                  </h5>
                  <button
                    class="btn-close"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group  mb-3 position-relative ">
                      <label class="small mb-1"> Bank Name </label>
                      <input
                        class="form-control  form-control-solid input-copy"
                        value={bankName}
                        type="text"
                        Placeholder="Enter Bank Name"
                        onChange={(e) => setBankName(e.target.value)}
                      ></input>
                    </div>
                    <div className="form-group  mb-3 position-relative ">
                      <label class="small mb-1"> Account Number</label>
                      <input
                        class="form-control  form-control-solid input-copy"
                        type="text"
                        Placeholder="Enter Account Number"
                        value={accNumber}
                        onChange={(e) => setAccNumber(e.target.value)}
                      ></input>
                    </div>
                    <div className="form-group  mb-3 position-relative ">
                      <label class="small mb-1"> Account Holder Name </label>
                      <input
                        class="form-control  form-control-solid input-copy"
                        type="text"
                        Placeholder="Enter Account Holder Name"
                        value={holderName}
                        onChange={(e) => setHolderName(e.target.value)}
                      ></input>
                    </div>
                    <div className="form-group  mb-3 position-relative ">
                      <label class="small mb-1"> IFSC Code </label>
                      <input
                        class="form-control  form-control-solid input-copy"
                        type="text"
                        Placeholder="Enter IFSC"
                        value={ifsc}
                        onChange={(e) => setIfsc(e.target.value)}
                      ></input>
                    </div>
                    <div className="form-group  mb-3 position-relative ">
                      <label class="small mb-1"> Branch Name </label>
                      <input
                        class="form-control  form-control-solid input-copy"
                        type="text"
                        Placeholder="Enter Branch Name"
                        value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                      ></input>
                    </div>
                    <div className="form-group  mb-3 position-relative">
                      <button
                        class="btn btn-primary   btn-block w-100"
                        type="button"
                        onClick={() =>
                          handleBankAccDetails(
                            id,
                            bankName,
                            accNumber,
                            holderName,
                            ifsc,
                            branchName
                          )
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default MasterWallet;
