import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";

const ApprovedKyc = () => {
    const { SearchBar } = Search;
    const [data, setData] = useState([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [rejectReason, setRejectReason] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [Id,setId] = useState("")

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "dateFrom":
                setDateFrom(event.target.value);
                break;
            case "dateTo":
                setDateTo(event.target.value);
                break;
                case "rejectReason":
                    setRejectReason(event.target.value);
                break;    
            default:
        }
    }


    const  imageFormatter = (cell, row) => {
        return <img className="table-img" src={"https://api.polarize.exchange/" + row?.user_selfie} alt="Selfy" />;
    }

    const dateFormatter =(cell, row)  =>{
        return moment(row?.createdAt).format('DD-MM-YYYY');
    }
   

      const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                

                <button
                            class="btn btn-danger btn-block"
                            data-bs-toggle="modal"
                            data-bs-target="#rejectmodal"
                            type="button"
                            onClick={()=>setId(row?.userId)}
                          >
                            Reject
                          </button>

            </>
        );
    };


    const columns = [
        { dataField: '_id', text: 'ID' },
        { dataField: 'first_name', text: 'FirstName', sort: true, },
        { dataField: 'last_name', text: 'LastName', sort: true, },
        { dataField: 'document_number', text: 'Document Number', sort: true},
        { dataField: 'pancard_number', text: 'Pan Number', sort: true },
        { dataField: 'selfiePath', text: 'Selfie', sort: true, formatter: imageFormatter },
        { dataField: 'dob', text: 'DOB', sort: true, },
        { dataField: 'createdAt', text: 'Created At', sort: true, formatter: dateFormatter },
        { dataField: 'Action', text: 'Action', formatter: linkFollow },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });

    useEffect(() => {
        handleData()
    }, []);

    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getdataverifylist().then(async result => {
            LoaderHelper.loaderStatus(false);
            if (result.data) {
                try {
                    setData(result.data.reverse());
                    setDateFrom("");
                    setDateTo("");
                } catch (error) {
                    alertErrorMessage('No data found');
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage('No data found');
            }
        });
    }

    const handleExportData = async (dateFrom, dateTo) => {
        await AuthService.exportApprovedList(dateFrom, dateTo).then(async result => {
            if (result.data.length > 0) {
                try {
                    setData(result.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage("No Data Found");
                console.log(result, 'query');

            }
        });
    }

    const verifyIdentity = async (id, status, reason) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getverifyidentity(id, status, reason).then(
          async (result) => {
            if (result?.success) {
              LoaderHelper.loaderStatus(false);
              try {
                alertSuccessMessage(result?.message);
                $("#rejectmodal").modal("hide");
                handleData()
              } catch (error) {
                alertErrorMessage(error);
              }
            } else {
              LoaderHelper.loaderStatus(false);
              alertErrorMessage(result.message);
            }
          }
        );
      };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"> <i className="fa fa-user-check" ></i></div>
                                        Approved Kyc
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                {data.length === 0 ? (
            ""
          ) : (
                    <div className="filter_bar" >
                        <form className="row" >
                            <div class="mb-3 col ">
                                <input type="date" class="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateFrom" value={dateFrom} onChange={handleInputChange} />
                            </div>
                            <div class="mb-3 col ">
                                <input type="date" class="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={dateTo} onChange={handleInputChange} />
                            </div>
                            <div class="mb-3 col ">
                                <div className="row" >
                                    <div className="col" >
                                        <button class="btn btn-indigo btn-block w-100" type="button" onClick={() => handleExportData(dateFrom, dateTo)} disabled={!dateFrom || !dateTo}>Search</button>
                                    </div>
                                    <div className="col" >
                                        <button className="btn btn-indigo btn-block w-100" type="button" onClick={handleData}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>)}
                    <div className="card mb-4">
                        <div class="card-header">Approved Kyc List
                        {data.length === 0 ? (
                ""
              ) : (
                            <div class="dropdown">
                                <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download me-3"></i>Export</button>
                                <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <CSVLink data={data} class="dropdown-item">Export as CSV</CSVLink>
                                </div>
                            </div>)}
                        </div>
                        <div className="card-body">
                        {data.length === 0 ? (
                  <h6 className="ifnoData">
                    <img src="assets/img/no-data.png" /> <br /> No Data
                    Available
                  </h6>
                ) : (
                            <div class="table-responsive">
                                <ToolkitProvider
                                    hover
                                    bootstrap4
                                    keyField='_id'
                                    columns={columns}
                                    data={data}
                                    exportCSV
                                    search
                                >
                                    {
                                        props => (
                                            <React.Fragment>
                                                <SearchBar {...props.searchProps} />
                                                <BootstrapTable
                                                    hover
                                                    bootstrap4
                                                    keyField='_id'
                                                    columns={columns}
                                                    data={data}
                                                    pagination={pagination}
                                                    filter={filterFactory()}
                                                    {...props.baseProps}
                                                />
                                            </React.Fragment>
                                        )
                                    }
                                </ToolkitProvider>
                            </div>)}
                        </div>
                    </div>
                </div>

                 {/* alert modal data */}
      <div
        class="modal"
        id="rejectmodal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered alert_modal"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Are You Sure{" "}
                <b>
                  {firstName} {lastName}
                </b>{" "}
                ?
              </h5>
              <button
                class="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group mb-4 ">
                  <label className="small mb-1"> Enter Reason </label>
                  <textarea
                    className="form-control form-control-solid"
                    rows="7"
                    placeholder="Enter your message.."
                    value={rejectReason}
                    name="rejectReason"
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <button
                  class="btn btn-danger btn-block w-100"
                  type="button"
                  disabled={!rejectReason}
                    onClick={() => verifyIdentity(Id, 3, rejectReason)}
                >
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* alert modal data */}
            </main>
        </div>
    )
}

export default ApprovedKyc;