import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const FiatDManagement = () => {
    const { SearchBar } = Search;
    const [fiatDeposit, setFiatDeposit] = useState([]);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [showImage, setShowImage] = useState('');

    const linkFollowStatus = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <span className={`badge  rounded-pill ml-35 ${row?.status === 'PENDING' ? "bg-warning" : row?.status === 'CANCELLED' ? "bg-danger" : row?.status === 'APPROVE' ? "bg-success" :  row?.status === 'SUCCESS' ? "bg-success" : ""} `}>{row?.status}</span>
            </>
        );
    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button className="btn btn-danger btn-icon btn-sm me-2" onClick={() => handleDenied(row?._id, row?.userId)}><i className="fa fa-times" ></i></button>
                <button className="btn btn-success  btn-icon btn-sm" onClick={() => handleApprove(row?._id, row?.userId)}><i className="fa fa-check" ></i></button>
            </>
        );
    };

    const dateFilter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {moment(row?.createdAt).format('Do MMMM YYYY, h:mm:ss a')}
            </>
        );
    };

    const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {row?.firstName + ' ' + row?.lastName}
            </>
        );
    };
    const handleShowImage = (img) => {
        setShowImage(img);
    }

    const linkImage = (cell, row, rowIndex, formatExtraData) => {
        return (
            !row?.deposit_slip ?
                <img class="table-img" src="assets/img/illustrations/profiles/nophoto.png" />
                :

                <img className="table-img" style={{ cursor: 'pointer' }} src={"https://api.polarize.exchange/" + row?.deposit_slip} data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleShowImage("https://api.polarize.exchange/" + row?.deposit_slip)}
                />
        );
    };

    const columns = [
        { dataField: 'userName', text: 'User Name', sort: true, formatter: nameFormatter },
        { dataField: 'emailId', text: 'Email', sort: true, },
        { dataField: 'mobileNumber', text: 'Mobile Number', sort: true, },
        { dataField: 'amount', text: 'Amount', sort: true, },
        { dataField: 'transaction_number', text: 'Transaction Number', sort: true, },
        { dataField: 'transaction_type', text: 'Payment Type', sort: true, },
        { dataField: 'createdAt', text: 'Date & Time', sort: true, formatter: dateFilter },
        { dataField: 'image', text: 'Deposit Slip', sort: true, formatter: linkImage },
        { dataField: 'status', text: 'Status', sort: true, formatter: linkFollowStatus },
        { dataField: 'Action', text: 'Action', sort: true, formatter: linkFollow },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    useEffect(() => {
         handleFiatDeposit()
    }, []);

    const handleFiatDeposit = async () => {
        LoaderHelper.loaderStatus(true)
        await AuthService.getFiatDeposit().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false)
                try {
                    setFiatDeposit(result.data.reverse());
                    setDateFrom("");
                    setDateTo("");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false)
                alertErrorMessage("No data found");
            }
        });
    }


    const handleDenied = async (id, userId) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.handleFiatDrejected(id, userId).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false)
                handleFiatDeposit();
            } else {
                LoaderHelper.loaderStatus(false)
                alertErrorMessage(result.data)
            }
        })
    }

    const handleApprove = async (id, userId) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.handleFiatDApprove(id, userId).then(async result => {
            LoaderHelper.loaderStatus(false)
            if (result.success) {
                handleFiatDeposit();
            } else {
                alertErrorMessage(result.data)
            }
        })
    }

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "dateFrom":
                setDateFrom(event.target.value);
                break;
            case "dateTo":
                setDateTo(event.target.value);
                break;
            default:
        }
    }


    const handleExportData = async (dateFrom, dateTo) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.exportFiatDeposit(dateFrom, dateTo).then(async result => {
            if (result.data.length > 0) {
                LoaderHelper.loaderStatus(false)
                try {
                    setFiatDeposit(result.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false)
                alertErrorMessage("No Data Found");
            }
        });
    }


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-feather"></i></div>
                                        Fiat Deposit
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Main page content */}
                <div className="container-xl px-4 mt-n10">
                {fiatDeposit.length === 0 ? "" :
                    <div className="filter_bar" >
                        <form className="row" >
                            <div class="mb-3 col ">
                                <input type="date" class="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateFrom" value={dateFrom} onChange={handleInputChange} />
                            </div>
                            <div class="mb-3 col ">
                                <input type="date" class="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={dateTo} onChange={handleInputChange} />
                            </div>
                            <div class="mb-3 col ">
                                <div className="row" >
                                    <div className="col" >
                                        <button class="btn btn-indigo btn-block w-100" type="button" onClick={() => handleExportData(dateFrom, dateTo)} disabled={!dateFrom || !dateTo}>Search</button>
                                    </div>
                                    <div className="col" >
                                        <button className="btn btn-indigo btn-block w-100" type="button" onClick={handleFiatDeposit}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>}
                    <div className="card mb-4">
                        <div class="card-header">
                            Fiat Deposit Details
                            <div class="dropdown">
                                <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-download me-3"></i> Export
                                </button>
                                <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <CSVLink data={fiatDeposit} class="dropdown-item">Export as CSV</CSVLink>
                                </div>
                            </div>
                        </div>

                        {fiatDeposit.length === 0 ? (
                <h6 className="ifnoData">
                  <img src="assets/img/no-data.png" /> <br />
                  No Data Available
                </h6>
              ) : (
                        <div className="card-body mt-3">
                            <table width="100%">
                                <ToolkitProvider
                                    hover
                                    bootstrap4
                                    keyField='_id'
                                    columns={columns}
                                    data={fiatDeposit}
                                    exportCSV
                                    search >
                                    {
                                        props => (
                                            <React.Fragment>
                                                <SearchBar {...props.searchProps} />
                                                <BootstrapTable
                                                    hover
                                                    bootstrap4
                                                    keyField='_id'
                                                    columns={columns}
                                                    data={fiatDeposit}
                                                    pagination={pagination}
                                                    filter={filterFactory()}
                                                    {...props.baseProps}
                                                />
                                            </React.Fragment>
                                        )
                                    }
                                </ToolkitProvider>
                            </table>
                        </div>)}
                    </div>
                </div>
            </main>

            <div class="modal image_modal" id="edit_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog  alert_modal modal-lg" role="document">
                    <div class="modal-content">
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div class="ratio ratio-16x9">
                            <img src={showImage} class="w-100 cc_modal_img " alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FiatDManagement;




