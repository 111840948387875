import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { $ } from 'react-jquery-plugin';
const BannerManagement = () => {

    const { SearchBar } = Search;
    const [selectBanner, setSelectBanner] = useState('');
    const [sequence, setSequence] = useState('');
    const [bannerFile, setBannerFile] = useState('');
    const [bannerList, setBannerList] = useState('');
    const [editBanner, setEditBanner] = useState('');
    const [editSequence, setEditSequence] = useState('');
    const [editBannerFile, setEditBannerFile] = useState('');
    const [bannerId, setBannerId] = useState('');

    const handleChangeIdentity = async (event) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        setBannerFile(fileUploaded);
    }

    const resetInputChange = () => {
        setSelectBanner("");
        setSequence("");
        document.getElementById("bannerImg").value = "";
    }

    const handleAddBanner = async (selectBanner, sequence, bannerFile) => {
        var formData = new FormData();
        formData.append('banner_type', selectBanner);
        formData.append('banner_sequence', sequence);
        formData.append('banner_image', bannerFile);
        LoaderHelper.loaderStatus(true);
        await AuthService.AddBanner(formData).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    resetInputChange();
                    handleBanners();
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                const errorMessage = result.message;
                alertErrorMessage(errorMessage);
            }
        });

    }

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <button type="button" className="btn btn-sm btn-dark me-2" data-bs-toggle="modal" data-bs-target="#edit_banner" onClick={() => {
                    setBannerId(row?._id);
                }}>Edit</button>
                <button class="btn btn-danger btn-sm" type="button" onClick={() => deleteBanner(row?._id)}>
                    Delete
                </button>
            </div>
        );
    };

    const statuslinkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button type="button" class={cell === "Active" ? "btn btn-sm btn-primary" : "btn btn-sm btn-danger"} style={{ marginLeft: "20px" }} onClick={() => handleStatus(row?._id, cell === "Active" ? "Inactive" : "Active")}>{cell}</button>
            </>
        );
    };

    function imageFormatter(cell, row) {
        return (
            <a href={"https://api.polarize.exchange/" + row?.banner_path} target="_blank" rel="noreferrer" > <img className="table-img" src={"https://api.polarize.exchange/" + row?.banner_path} alt="Banner Img" />
            </a>
        );
    }

    const columns = [

        { dataField: '_id', text: 'ID' },
        { dataField: 'banner_type', text: 'Banner Type', sort: true, },
        { dataField: 'banner_path', text: 'Banner Image	', sort: true, formatter: imageFormatter },
        { dataField: 'banner_sequence', text: 'Sequence', sort: true, },
        { dataField: 'status', text: 'Status', sort: true, formatter: statuslinkFollow },
        { dataField: 'Action', text: 'Action', formatter: linkFollow },

    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    useEffect(() => {
         handleBanners()
    }, []);

    const handleBanners = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getBannerList().then(async result => {
            if (result.data) {
                LoaderHelper.loaderStatus(false);
                try {
                    setBannerList(result.data);
                } catch (error) {
                    alertErrorMessage(error);
                    /* console.log('error', `${error}`); */
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("No data found");

            }
        });
    }



    const handleEditBanner = async (event) => {
        event.preventDefault();
        const fileUploaded = event.target.files[0];
        setEditBannerFile(fileUploaded);
    }

    const resetEditInput = () => {
        setEditBanner("");
        setEditSequence("");
        document.getElementById("EditbannerImg").value = "";
    }

    const handleUpdateBanner = async (editBanner, editSequence, editBannerFile, bannerId) => {
        var formData = new FormData();
        formData.append('banner_type', editBanner);
        formData.append('banner_sequence', editSequence);
        formData.append('banner_image', editBannerFile);
         formData.append('_id', bannerId);
         LoaderHelper.loaderStatus(true);
        await AuthService.updateBannerList(formData).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    $('#edit_banner').modal('hide');
                    resetEditInput();
                    handleBanners();
                } catch (error) {
                    alertErrorMessage(error);
                    /* console.log("error", `${error}`); */
                }
            } else {
                LoaderHelper.loaderStatus(false);
                const errorMessage = result.message;
                alertErrorMessage(errorMessage);
            }
        });

    }

    const deleteBanner = async (userId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.deletebannerlist(userId).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage(result.message);
                handleBanners();
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message)
            }
        })
    }

    const handleStatus = async (userId, cell) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.handleBannerStatus(userId, cell).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                // alertSuccessMessage(result.massage);
                handleBanners();
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message)
            }
        })
    }





    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-image"></i></div>
                                            Banner Management
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="row" >
                            <div className="col-xl-4">
                                <div class="card mb-4 mb-xl-0">
                                    <div className="card-body d-flex justify-content-center flex-column p-5 ">
                                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                                            <h5 className="mb-0" >Add New Banner</h5>
                                        </div>
                                        <form>
                                            <div class="mb-3 form-group">
                                                <label class="small mb-1" for="inputLocation">Banner Type </label>
                                                <select class="form-control form-control-solid form-select" id="exampleFormControlSelect1" name="selectBanner" value={selectBanner} onChange={(event) => setSelectBanner(event.target.value)}>
                                                    <option>Select Banner Type</option>
                                                    <option value="offerBanner">  Offer Banner </option>
                                                    <option value="invite">Invite</option>
                                                </select>
                                            </div>
                                            <div className="form-group  mb-3" >
                                                <label class="small mb-1">Banner Sequence</label>
                                                <input class="form-control  form-control-solid" type="text" placeholder="Enter Sequence" name="sequence" value={sequence} onChange={(event) => setSequence(event.target.value)}></input>
                                            </div>
                                            <div className="form-group  mb-3" >
                                                <label class="small mb-1">Banner Image <small className="text-dark ms-1" >(600X400)</small> </label>
                                                <input class="form-control  form-control-solid" id="bannerImg" type="file" name="bannerFile" onChange={handleChangeIdentity}></input>
                                            </div>
                                            <button class="btn btn-indigo   btn-block w-100 mt-2" type="button" onClick={() => handleAddBanner(selectBanner, sequence, bannerFile)} disabled={!selectBanner || !bannerFile}> Submit </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8" >
                                <div class="card">
                                    <div class="card-header">
                                        Banners List
                                        <div class="dropdown">
                                            <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export{" "}
                                            </button>
                                            <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                <CSVLink data={bannerList} class="dropdown-item">Export as CSV</CSVLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" >
                                        <form className="row" >
                                            <div className="col-12" >
                                                <table width="100%" >
                                                    {bannerList.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" /> <br /> No Data Available</h6> :
                                                        <ToolkitProvider
                                                            hover
                                                            bootstrap4
                                                            keyField='_id'
                                                            columns={columns}
                                                            data={bannerList}
                                                            exportCSV
                                                            search >
                                                            {
                                                                props => (
                                                                    <React.Fragment>
                                                                        <SearchBar {...props.searchProps} />
                                                                        <BootstrapTable
                                                                            hover
                                                                            bootstrap4
                                                                            keyField='_id'
                                                                            columns={columns}
                                                                            data={bannerList}
                                                                            pagination={pagination}
                                                                            filter={filterFactory()}
                                                                            {...props.baseProps}
                                                                        />
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                        </ToolkitProvider>
                                                    }
                                                </table>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>


            {/* Currency Pair modal data */}
            <div class="modal" id="edit_banner" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog  alert_modal" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">
                                Edit Banner
                            </h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div class="mb-3 form-group">
                                    <label class="small mb-1" for="inputLocation">Banner Type </label>
                                    <select class="form-control form-control-solid form-select" id="exampleFormControlSelect1" name="selectBanner" value={editBanner} onChange={(event) => setEditBanner(event.target.value)}>
                                        <option>Select Banner Type</option>
                                        <option value="offerBanner">  Offer Banner </option>
                                        <option value="invite">Invite</option>
                                    </select>
                                </div>
                                <div className="form-group  mb-3" >
                                    <label class="small mb-1">Banner Sequence</label>
                                    <input class="form-control  form-control-solid" type="text" placeholder="Enter Sequence" name="sequence" value={editSequence} onChange={(event) => setEditSequence(event.target.value)}></input>
                                </div>
                                <div className="form-group  mb-3" >
                                    <label class="small mb-1">Banner Image <small className="text-dark ms-1" >(600X400)</small> </label>
                                    <input class="form-control  form-control-solid" id="EditbannerImg" type="file" name="editBannerFile" onChange={handleEditBanner} ></input>
                                </div>
                                <button class="btn btn-indigo btn-block w-100 mt-2" type="button" onClick={() => handleUpdateBanner(editBanner, editSequence, editBannerFile, bannerId)}> Update Banner </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Currency Pair modal data */}
        </>
    )
}

export default BannerManagement;