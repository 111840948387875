import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import ApprovedKyc from "../ApprovedKyc";
import PendingKyc from "../PendingKyc";
import TradeList from "../TradeList";
import SupportPage from "../SupportPage";
import FiatDManagement from "../FiatDManagement";
import FiatManagement from "../FiatManagement";
import TodayRegestration from "../TodayRegestration";
import TodayDeposit from "../TodayDeposit";
import TodayWithdraw from "../TodayWithdraw";
const HomePage = () => {

    const myPermission = sessionStorage.getItem('permissions');
    const Permissions = myPermission ? JSON.parse(myPermission) : [];
    const permissions =  Permissions.map(item=>item.value);
   const userType = sessionStorage.getItem('userType');
   // const userType = '1' ;
   // const permissions = [1,2,3,4,5,6,7,8,9,10,11,12];

    const [activeScreen, setActiveScreen] = useState('dashboard');
    const [totalUser, setTotalUser] = useState("");
    const [totalVerified, setTotalVerified] = useState("");
    const [totalPending, setTotalPending] = useState("");
    const [registration, setRegistration] = useState("");
    const [totalDeposit, setTotalDeposit] = useState("");
    const [totalWithdraw, setTotalWithdraw] = useState("");

    const [toDayDepositRequest, setToDayDepositRequest] = useState("");
    const [totalSupport, setTotalSupport] = useState("");
    const [todayWithdrawal, setTodayWithdrawal] = useState("");


    useEffect(() => {
        totaluserData();
         totalVerifiedUser();
         totalPendingUser();
         newRegistrations();
        // toalWithdraw();
        // toalDeposit();
        /*  
          totalSupportUser();
          toDayWithdrawal();          
          toDayDeposit();
          
        */
    }, []);



    const totaluserData = async () => {
        await AuthService.getTotaluser().then(async result => {
            if (result) {
                try {
                    setTotalUser(result.data);
                    // alertSuccessMessage("sucess");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        })
    }

    const totalVerifiedUser = async () => {
        await AuthService.getTotalVerified().then(async result => {
            if (result) {
                try {
                    setTotalVerified(result.data);
                    //alertSuccessMessage("sucess");
                } catch (error) {
                    console.log(error, "error");
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        })
    }

    const totalPendingUser = async () => {
        await AuthService.getTotalPending().then(async result => {
            if (result) {
                try {
                    setTotalPending(result.data);
                    //alertSuccessMessage("sucess");
                } catch (error) {
                    alertErrorMessage(error)
                }
            } else {
                alertErrorMessage("Fail TotalPanding");
            }
        })
    }

    const totalSupportUser = async () => {
        await AuthService.getSupportUser().then(async result => {
            if (result) {
                try {
                    setTotalSupport(result?.TotalSupport);
                    //alertSuccessMessage("sucess");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage("Fail to Support Data");
            }
        })
    }

    const toDayWithdrawal = async () => {
        await AuthService.getWithdrawal().then(async result => {
            if (result) {
                try {
                    setTodayWithdrawal(result.data?.count);
                    //alertSuccessMessage("success");
                } catch (error) {
                    alertErrorMessage(error)
                }
            } else {
                alertErrorMessage("Fail to Withdrawal Data");
            }

        })
    }

    const toDayDeposit = async () => {
        await AuthService.getTodayDeposit().then(async result => {
            console.log(typeof result, "getNewRegistration");
            if (Object.keys(result).length > 0) {
                try {
                    setToDayDepositRequest(result?.data?.count);
                    //alertSuccessMessage("Sucess");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage("Failed to New Registration");
            }
        })
    }

    const newRegistrations = async () => {
        await AuthService.getNewRegistration().then(async result => {
            if (result.success) {
                try {
                    setRegistration(result?.data);
                    //alertSuccessMessage("Sucess");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage("Failed to New Registration");
            }
        })
    }

    const toalDeposit = async () => {
        await AuthService.getToalDeposit().then(async result => {
            if (Object.keys(result).length > 0) {
                try {
                    setTotalDeposit(result?.data?.count);
                    //alertSuccessMessage("Sucess");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage("Failed to New Registration");
            }
        })
    }

    const toalWithdraw = async () => {
        await AuthService.getToalWithdraw().then(async result => {
            if (Object.keys(result).length > 0) {
                try {
                    setTotalWithdraw(result?.data?.count);
                    //alertSuccessMessage("Sucess");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage("Failed to New Registration");
            }
        })
    }


    return (
        activeScreen === 'dashboard' ?
            <>
                <div id="layoutSidenav_content">
                    <main>
                        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div className="container-xl px-4">
                                <div className="page-header-content pt-4">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto mt-4">
                                            <h1 className="page-header-title">
                                                <div className="page-header-icon"><i className="fa fa-th" ></i></div>
                                                Dashboard
                                            </h1>
                                            {/* <div className="page-header-subtitle">Example dashboard overview and content summary</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <div className="container-xl px-4 mt-n10">
                            <div className="row">

                                {permissions.includes(1) || userType === '1' ?

                                    <div className="col-lg-6 col-xl-4 mb-4">
                                        <div className="card bg-primary text-white h-100">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-white-75">Total Users</div>
                                                        <div className="display-4 fw-bold">{totalUser}</div>
                                                    </div>
                                                    <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                                </div>
                                            </div>

                                            <div className="card-footer d-flex align-items-center justify-content-between small">

                                                <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("totaluser")}>View All</Link>

                                                <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }

                                {permissions.includes(2) || userType === '1' ?
                                    <>
                                        <div className="col-lg-6 col-xl-4 mb-4">
                                            <div className="card bg-success text-white h-100">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="me-3">
                                                            <div className="text-white-75">Total Verified Users</div>
                                                            <div className="display-4 fw-bold">{totalVerified}</div>
                                                        </div>
                                                        <i className="feather-xl text-white-50 fa fa-user-check"></i>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                                    <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("totalVerified")}>View All</Link>
                                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-4">
                                            <div className="card bg-danger text-white h-100">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="me-3">
                                                            <div className="text-white-75 ">Total Pending Kyc's</div>
                                                            <div className="display-4 fw-bold">{totalPending}</div>
                                                        </div>
                                                        <i className="feather-xl text-white-50 fa fa-user-slash "></i>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                                    <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("pendingkyc")}>View All</Link>
                                                    <div className="text-white"><i className="fa fa-angle-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }

                            </div>

                            <div className="row">
                                {userType === '1' ?
                                    <div className="col-lg-6 col-xl-4 mb-4">
                                        <div className="card bg-dark text-white h-100">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-white-75 ">Today's New Registrations</div>
                                                        <div className="display-4 fw-bold">{registration}</div>
                                                    </div>
                                                    <i className="feather-xl text-white-50 fa fa-user-plus"></i>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("newRegistrations")}>View All</Link>
                                                <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }


                                {/* <div className="col-lg-6 col-xl-4 mb-4">
                                    <div className="card bg-secondary text-white h-100">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="me-3">
                                                    <div className="text-white-75 ">Today's Deposit Request</div>
                                                    <div className="display-4 fw-bold">{toDayDepositRequest}</div>
                                                </div>
                                                <i className="feather-xl text-white-50 fa fa-wallet"></i>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex align-items-center justify-content-between small">
                                            <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("toDayDeposit")}>View All</Link>
                                            <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                        </div>
                                    </div>
                                </div>*/}
                                {/* <div className="col-lg-6 col-xl-4 mb-4">
                                    <div className="card bg-warning text-white h-100">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="me-3">
                                                    <div className="text-white-75 ">Today's Withdrawal Request</div>
                                                    <div className="display-4 fw-bold">{todayWithdrawal}</div>
                                                </div>
                                                <i className="feather-xl text-white-50 fa fa-wallet "></i>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex align-items-center justify-content-between small">
                                            <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("todayWithdrawl")}>View All</Link>
                                            <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                        </div>
                                    </div>
                                </div> */}


                                {/* {permissions.includes(9) || userType === '1' ?
                                    <>
                                        <div className="col-lg-6 col-xl-4 mb-4">
                                            <div className="card bg-danger text-white h-100">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="me-3">
                                                            <div className="text-white-75 ">Total Fiat Withdrawl Request</div>
                                                            <div className="display-4 fw-bold">{totalWithdraw}</div>
                                                        </div>
                                                        <i className="feather-xl text-white-50 fa fa fa-wallet"></i>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                                    <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("totalWithdraw")}>View All</Link>
                                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-4 mb-4">
                                            <div className="card bg-info text-white h-100">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="me-3">
                                                            <div className="text-white-75 ">Total Fiat Deposit Request</div>
                                                            <div className="display-4 fw-bold">{totalDeposit}</div>
                                                        </div>
                                                        <i className="feather-xl text-white-50 fa fa fa-wallet"></i>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex align-items-center justify-content-between small">
                                                    <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("totalDeposit")}>View All</Link>
                                                    <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                } */}

                                {/*                                 <div className="col-lg-6 col-xl-4 mb-4">
                                    <div className="card bg-gradient-primary-to-secondary text-white h-100">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="me-3">
                                                    <div className="text-white-75 ">Suport</div>
                                                    <div className="display-4 fw-bold">{totalSupport}</div>
                                                </div>
                                                <i className="feather-xl text-white-50 fas fa-headset "></i>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex align-items-center justify-content-between small">
                                            <Link className="text-white stretched-link" to="" style={{ cursor: 'pointer' }} onClick={() => setActiveScreen("supportpage")}>View All</Link>
                                            <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </main>
                </div >
            </>
            : activeScreen === "totaluser" ? <TradeList /> : activeScreen === "totalVerified" ? <ApprovedKyc /> : activeScreen === "pendingkyc" ? <PendingKyc /> : activeScreen === "supportpage" ? <SupportPage /> : activeScreen === "toDayDeposit" ? <TodayDeposit /> : activeScreen === "newRegistrations" ? <TodayRegestration /> : activeScreen === "totalWithdraw" ? <FiatManagement /> : activeScreen === "totalDeposit" ? <FiatDManagement /> : activeScreen === "todayWithdrawl" ? <TodayWithdraw /> : undefined

    )
}
export default HomePage;