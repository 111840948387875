import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import TodayRegestrationDetails from "../TodayRegestrationDetails";
import { CSVLink } from "react-csv";

const TodayRegestration = (props) => {

    const [activeScreen, setActiveScreen] = useState('userdetail');
    const [toDayRegistration, setTodayRegistration] = useState([]);
    const [userId, setUserId] = useState('');
    const { SearchBar } = Search;

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <button className="btn btn-dark btn-sm   me-2"
                    onClick={() => {
                        setUserId(row?._id);
                        setActiveScreen('detail');
                    }}
                >
                    View
                </button>
            </div>
        );
    };

    const statuslinkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <div className={`badge ${row?.kycVerified === 0 ? "bg-secondary" : row?.kycVerified === 1 ? "bg-warning" : row?.kycVerified === 2 ? "bg-success" : row?.kycVerified === 3 ? "bg-danger" : null} text-white rounded-pill `}>

                    {row?.kycVerified === 0 ? "Not Submited" : row?.kycVerified === 1 ? "Pending for Approval" : row?.kycVerified === 2 ? "Approved" : row?.kycVerified === 3 ? "Rejected" : null}
                </div>
            </>
        );
    };

    const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                {row?.firstName} {row?.lastName}
            </>
        );
    };

    const columns = [
        { dataField: '_id', text: 'User ID' },
        { dataField: 'Name', text: 'Name', sort: true, formatter: nameFormatter },
        { dataField: 'emailId', text: 'Email', sort: true, },
        { dataField: 'mobileNumber', text: 'Phone Number', sort: true, },
        { dataField: 'createdAt', text: 'Registration Date', sort: true, },
        { dataField: 'kycVerified', text: 'Kyc Status', sort: true, formatter: statuslinkFollow },
        { dataField: 'Action', text: 'Action', formatter: linkFollow },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    useEffect(() => {
         handleData()
    }, []);

    const handleData = async () => {
        await AuthService.getTodayRegistration().then(async result => {
            if (result.success) {
                try {
                    setTodayRegistration(result?.data.reverse());
                } catch (error) {
                    alertErrorMessage(error)
                    // console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        activeScreen === 'userdetail' ?
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-wave-square" ></i></div>
                                            Today's Registrations List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="filter_bar" >
                        </div>
                        <div className="card mb-4">
                            <div class="card-header">Today's Registrations Details
                                {!toDayRegistration ? "" :
                                    <div class="dropdown">
                                        <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                        <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                            <CSVLink data={toDayRegistration} class="dropdown-item">Export as CSV</CSVLink>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card-body">
                                <div class="table-responsive">
                                    {!toDayRegistration ? <h6 className="ifnoData"><img src="assets/img/no-data.png" /> <br /> No Registration Today</h6> :
                                        <ToolkitProvider
                                            hover
                                            bootstrap4
                                            keyField='_id'
                                            columns={columns}
                                            data={toDayRegistration}
                                            exportCSV
                                            search={{
                                                afterSearch: (newResult) => console.log(newResult)
                                            }}>
                                            {props => (
                                                <React.Fragment>
                                                    <SearchBar {...props.searchProps} />
                                                    <BootstrapTable
                                                        hover
                                                        bootstrap4
                                                        keyField='_id'
                                                        columns={columns}
                                                        data={toDayRegistration}
                                                        pagination={pagination}
                                                        filter={filterFactory()}
                                                        {...props.baseProps}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            :
            <TodayRegestrationDetails userId={userId} />
    )
}

export default TodayRegestration;