import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";

const TodayWithdraw = () => {

    const { SearchBar } = Search;
    const [toDayWithdraw, setTodayWithdraw] = useState([]);

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button className="btn btn-danger btn-sm me-2" onClick={() => handleDenied(row?._id)}>Denied</button>
                <button className="btn btn-success  btn-sm" onClick={() => handleApprove(row?._id)}>Approve</button>
            </>
        );
    };

    const linkStatus = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <span className={`badge text-white rounded-pill ${row?.status === 'PENDING' ? "bg-warning" : row?.status === 'REJECTED' ? "bg-danger" : row?.status === 'CONFIRM' ? "bg-success" : ""} `}>{row?.status}</span>
            </>
        );
    };

    const columns = [
        { dataField: 'userName', text: 'User Name' },
        { dataField: 'emailId', text: 'Email', sort: true, },
        { dataField: 'amount', text: 'Amount', sort: true, },
        { dataField: 'transactionType', text: 'Payment Type', sort: true, },
        { dataField: 'createdAt', text: 'Date & Time', sort: true, },
        { dataField: 'status', text: 'Status', sort: true, formatter: linkStatus },
        { dataField: 'Action', text: 'Action', formatter: linkFollow },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    useEffect(() => {
        // handleTodayWithdraw()
    }, []);

    const handleTodayWithdraw = async () => {
        await AuthService.getTodayWithdraw().then(async result => {
            if (result.success) {
                try {
                    setTodayWithdraw(result.data?.data2.reverse());
                } catch (error) {
                    /*  alertErrorMessage(error); */
                }
            } else {
                alertErrorMessage("Something Went Wrong");
            }
        });
    }

    const handleDenied = async (userId) => {
        await AuthService.handleFiatDenied(userId).then(async result => {
            if (result.data.msg == "Rejected") {
                // alertSuccessMessage(result.data.msg);
                handleTodayWithdraw();
            } else {
                alertErrorMessage(result.data.msg)
            }
        })
    }

    const handleApprove = async (userId) => {
        await AuthService.handleFiatApprove(userId).then(async result => {
            if (result.data.msg == "Approved") {
                // alertSuccessMessage(result.data.msg);
                handleTodayWithdraw();
            } else {
                alertErrorMessage(result.data.msg)
            }
        })
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-wallet"></i></div>
                                        Today Withdrawal
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Main page content */}
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div class="card-header">
                            Today Withdrawal Details
                            {toDayWithdraw.length === 0 ? "" :
                                <div class="dropdown">
                                    <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Export
                                    </button>
                                    <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <CSVLink data={toDayWithdraw} class="dropdown-item">Export as CSV</CSVLink>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="card-body mt-3">
                            <table width="100%">
                                {
                                    toDayWithdraw.length === 0 ? <h6 className="ifnoData">No Today Withdraw Available</h6> :
                                        <ToolkitProvider
                                            hover
                                            bootstrap4
                                            keyField='_id'
                                            columns={columns}
                                            data={toDayWithdraw}
                                            exportCSV
                                            search={{
                                                afterSearch: (newResult) => console.log(newResult)
                                            }}
                                        >
                                            {
                                                props => (
                                                    <React.Fragment>
                                                        <SearchBar {...props.searchProps} />
                                                        <BootstrapTable
                                                            hover
                                                            bootstrap4
                                                            keyField='_id'
                                                            columns={columns}
                                                            data={toDayWithdraw}
                                                            pagination={pagination}
                                                            filter={filterFactory()}
                                                            {...props.baseProps}
                                                        />
                                                    </React.Fragment>
                                                )
                                            }
                                        </ToolkitProvider>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default TodayWithdraw;