import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import Supportmessage from "../Supportmessage";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
const SupportPage = () => {

  const { SearchBar } = Search;
  const [issueList, setIssueList] = useState([]);
  const [emailId, setEmailID] = useState([]);
  const [description, setDescription] = useState([]);
  const [ticketId, setTicketId] = useState('');
  const [userId, setUserId] = useState('');
  const [activeScreen, setActiveScreen] = useState('support');

  const linkEmail = (cell, row, rowIndex, formatExtraData) => {
    console.log(row, 'row');
    return (
      <div>
        <span className="cursor-pointer" onClick={() => {
          setActiveScreen('supportmessage');
          setUserId(row?.userId);
          setTicketId(row?._id)
          setEmailID(row?.emailId);
          setDescription(row?.description);
        }}>
          <span className={row?.seen != 2 ? "fw-bold" : ""}>{row?.emailId} </span>
        </span>
      </div>
    );
  };

  const linkSubject = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span className="cursor-pointer" onClick={() => {
          setActiveScreen('supportmessage');
          setUserId(row?.userId);
          setTicketId(row?._id)
          setEmailID(row?.emailId);
          setDescription(row?.description);
        }} >
          <span className={row?.seen != 2 ? "fw-bold" : ""}>{row?.subject} </span>
        </span>
      </div>
    );
  };

  const linkDescription = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <span className="cursor-pointer" onClick={() => {
          setActiveScreen('supportmessage');
          setUserId(row?.userId);
          setTicketId(row?._id)
          setEmailID(row?.emailId);
          setDescription(row?.description);
        }} >
          <span className={row?.seen != 2 ? "fw-bold" : ""}>
            {row?.status} <small><i class={row?.seen !=2 ? "fa fa-circle ms-2 text-success": ""}></i></small>
          </span>
        </span>
      </div>
    );
  };

  const statuslinkFollow = (cell, row, rowIndex, formatExtraData) => {
    console.log(cell, 'rowRajendra');
    return (
      <>

        <button class="btn btn-sm btn-success" style={{ marginLeft: "20px" }} onClick={() => handleStatus(row?._id, row?.userId, cell === "Open" ? "Resolve" : "Resolve")}>{/* {cell} */}Resolve</button>
        <button class={cell === "Active" ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"} style={{ marginLeft: "20px" }} onClick={() => handleStatus(row?._id, row?.userId, cell === "Resolve" ? "Close" : "Close")}>Close</button>
      </>
    );
  };

  const handleStatus = async (Id, userId, cell) => {
    await AuthService.handleSubadminStatus(Id, userId, cell).then(async result => {
      if (result.massage === "Status update Successfully") {
        handleIssueList();
      } else {
        alertErrorMessage(result.message)
      }
    })
  }

  function imageFormatter(cell, row) {
    return <a href={ApiConfig?.uploadUrl + 'help/' + cell} target="_blank"><img className="table-img" src={ApiConfig?.uploadUrl + 'help/' + cell} /></a>;
  }

  const columns = [

    { dataField: 'issueImage', text: 'IssueImage', sort: true, formatter: imageFormatter },
    { dataField: 'emailId', text: 'Email Id', sort: true, formatter: linkEmail },
    { dataField: 'subject', text: 'Subject', sort: true, formatter: linkSubject },
    { dataField: 'status', text: 'Status', sort: true, formatter: linkDescription },
    { dataField: 'status', text: 'Change Status', formatter: statuslinkFollow },
  ]

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });


  useEffect(() => {
    /* handleIssueList() */
  }, []);

  const handleIssueList = async () => {
    LoaderHelper.loaderStatus(false);
    await AuthService.IssueList().then(async result => {
      if (result.data.length > 0) {
        LoaderHelper.loaderStatus(false);
        try {
          setIssueList(result?.data?.reverse());
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("Something Went Wrong");
      }
    });
  }

  return (

    activeScreen === 'support' ?
      <>
        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
              <div className="container-xl px-4">
                <div className="page-header-content pt-4">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto mt-4">
                      <h1 className="page-header-title">
                        <div className="page-header-icon"><i className="fa fa-question"></i></div>
                        Support Page
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {/* Main page content */}
            <div className="container-xl px-4 mt-n10">
              <div className="card mb-4">
                <div class="card-header">Issue List
                  <div class="dropdown">
                    <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export </button>
                    <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                      <CSVLink data={issueList} class="dropdown-item">Export as CSV</CSVLink>
                    </div>
                  </div>
                </div>
                <div className="card-body mt-3">
                  <table className="" width="100%" >
                    <ToolkitProvider

                      hover
                      bootstrap4
                      keyField='_id'
                      columns={columns}
                      data={issueList}
                      exportCSV
                      search={{
                        afterSearch: (newResult) => console.log(newResult)
                      }}
                    >
                      {
                        props => (
                          <React.Fragment>
                            <SearchBar {...props.searchProps} />
                            <BootstrapTable
                              hover
                              bootstrap4
                              keyField='_id'
                              columns={columns}
                              data={issueList}
                              pagination={pagination}
                              filter={filterFactory()}
                              {...props.baseProps}
                            />
                          </React.Fragment>
                        )
                      }
                    </ToolkitProvider>
                  </table>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
      :
      <Supportmessage userId={userId} id={ticketId} email={emailId} description={description} />
  )
}

export default SupportPage;


