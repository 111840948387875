import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertSuccessMessage, alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import { $ } from 'react-jquery-plugin';

const PriceManagement = () => {

    const [currentPrice, setCurrentPrice] = useState('');
    const [newPrice, setNewPrice] = useState('');

    const handleUpdatePrice = async (newPrice) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.updateprice(newPrice).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    setNewPrice('');
                    handleGetCurrentPrice();
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                const errorMessage = result.message;
                alertErrorMessage(errorMessage);
            }
        });

    }


    useEffect(() => {
        handleGetCurrentPrice()
    }, []);

    const handleGetCurrentPrice = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getCurrentPrice().then(async result => {
            console.log(result, 'handleGetCurrentPrice');
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    setCurrentPrice(result?.data[0]?.buy_price);
                } catch (error) {
                    alertErrorMessage(error);
                    /* console.log('error', `${error}`); */
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("No data found");

            }
        });
    }





    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-image"></i></div>
                                            Price Management
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="row" >
                            <div className="col-xl-6">
                                <div class="card mb-4 mb-xl-0">
                                    <div className="card-body d-flex justify-content-center flex-column p-5 ">
                                        <div className="d-flex align-items-center justify-content-start mb-4 ">
                                            <h5 className="mb-0" >Add New Price</h5>
                                        </div>
                                        <form>
                                            <div class="mb-3 form-group">
                                                <label class="small mb-1" for="inputLocation">Current PLR Price </label>
                                                <input class="form-control  form-control-solid" type="text" placeholder="Current Price" name="sequence" value={currentPrice} disabled></input>
                                            </div>
                                            <div className="form-group  mb-3" >
                                                <label class="small mb-1">Update PLR Price</label>
                                                <input class="form-control  form-control-solid" type="text" placeholder="Enter new Price" name="sequence" value={newPrice} onChange={(event) => setNewPrice(event.target.value)}></input>
                                            </div>
                                            <button class="btn btn-indigo   btn-block w-100 mt-2" type="button" onClick={() => handleUpdatePrice(newPrice)} disabled={!newPrice}> Submit </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default PriceManagement;