import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";

const PackageManagement = () => {
  const [packageList, setPackageList] = useState([]);
  const [minimumAmount, setMinimumAmount] = useState()
  const [maximumAmount, setMaximumAmount] = useState()
  const [time, setTime] = useState()
  const [monthPer, setMonthPer] = useState()
  const [expiryTime, setExpiryTime] = useState()
  const [referralMonth, setReferralMonth] = useState()
  const [referralPer, setReferralPer] = useState()
  const [remark, setRemark] = useState()
  const [status, setStatus] = useState()
  const [id,setId] = useState()
  const [phase, setPhase] = useState([])
  const [editPhase,setEditPhase] = useState()
  const [selectPhase,setSelectPhase] = useState(" ")

console.log(selectPhase, "kkkkk");
  
  useEffect(() => {
     if(selectPhase !== " "){
      handlePackageList(selectPhase);
     } 
  }, [selectPhase]);

  useEffect(()=>{
handlePhasesList()
  }, [])

console.log(editPhase,"paruuu");

  const handlePackageList = async (selectPhase) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.packageList(selectPhase).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setPackageList(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handlePhasesList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.phaseList().then(async (result) => {
      console.log(result,"yy")
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setPhase(result.data);
          if(selectPhase === " "){
            setSelectPhase(result.data[0])
          }
          
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };


  useEffect(()=>{ 
    if(selectPhase !== " "){
      handlePackageList(phase.includes(selectPhase) ? selectPhase : phase[0] );
    }
    
  },[phase])

  const handleDeletePackage = async (_id) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.deletePackage(_id).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          handlePhasesList()
          
          console.log(phase, "kkkpp");
          console.log(phase.includes(selectPhase), "asss");    
         
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const editPackage = async (editPhase,minimumAmount,maximumAmount,time,monthPer,referralMonth,referralPer,remark, expiryTime,id) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.editPackage(editPhase,minimumAmount,maximumAmount,time,monthPer,referralMonth,referralPer,remark, expiryTime,id).then(async (result) => {
      console.log(result)
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          $("#edit_modal").modal("hide");
         
           handlePackageList(editPhase);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  console.log(phase.includes(selectPhase), "selectPhase");

console.log(editPhase, "kkkk");

 
 

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-dollar-sign"></i>
                    </div>
                    Package Management
                  </h1>
                </div>
                <div class="col-3 mt-4">
                  <select
                    class="form-control form-control-solid form-select form-select-dark text-center"
                    id="exampleFormControlSelect1"
                    value={selectPhase}
                    onChange={(e) => setSelectPhase(e.target.value)}
                  >
                    {/* <option value="" selected="selected" hidden="hidden">
                      Choose here
                    </option> */}
                    {phase.map(item=>(
                       <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          {packageList.length === 0 ? (
            <h6 className="ifnoData">
              <img alt="" src="assets/img/no-data.png" />
              <br />
              No Data Available
            </h6>
          ) : (
            <div className="row">
              {packageList?.map((item, index) => (
                <div className="col-xl-4 mb-4">
                  <div className="card h-100">
                    <div className="card-body d-flex justify-content-center flex-column p-5 ">
                      
                      <form>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">
                            Phase
                          </label>
                          <input
                            class="form-control  form-control-solid"
                            
                            value={item?.phase_name}
                            name="phaseName"
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">  Minimum Amount ($)</label>
                          <input
                            class="form-control  form-control-solid"
                            
                            name="min_amount"
                            value={item?.min_amount}
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">  Maximum Amount ($)</label>
                          <input
                            class="form-control  form-control-solid"
                           
                            name="max_amount"
                            value={item?.max_amount}
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Duration (Year)</label>
                          <input
                            class="form-control  form-control-solid"
                             
                            name="time"
                            value={item?.month_time/12} 
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Month Percentage (%)</label>
                          <input
                            class="form-control  form-control-solid"
                            
                            name="monthPercentage"
                            value={item?.month_percentage}
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Expiry Time (Day)</label>
                          <input
                            class="form-control  form-control-solid"
                           
                            name="expiryTime"
                            value={item?.expiry_time}
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Months (Referral Commission only for direct)</label>
                          <input
                            class="form-control  form-control-solid"
                            
                            name="referral_month"
                            value={item?.referral_month }
                            //  onChange={(e) => handleInputChange(e.target.value)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Percentage (Referral Commission only for direct)</label>
                          <input
                            class="form-control  form-control-solid"
                            
                            name="referral_percentage"
                            value={item?.referral_percentage + "%"}
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Remark (Running upto)</label>
                          <input
                            class="form-control  form-control-solid"
                            
                            name="remark"
                            value={ item?.remark }
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        {/* <div className="form-group  mb-1">
                          <label class="small mb-1">Status</label>
                          <input
                            class="form-control  form-control-solid"
                            
                            name="status"
                            value={item?.status}
                            //  onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div> */}
                        
                        <button class="btn btn-indigo   btn-block w-100 mt-2" type="button" data-bs-toggle="modal" data-bs-target="#edit_modal"
                         onClick={() => {setEditPhase(item?.phase_name); setMinimumAmount(item?.min_amount); setMaximumAmount(item?.max_amount); setTime(item?.month_time); setMonthPer(item?.month_percentage); setExpiryTime(item?.month_percentage); setReferralMonth(item?.referral_month); setReferralPer(item?.referral_percentage); setRemark(item?.remark); setId(item?._id)}}
                         >
                          Edit
                        </button>
                        <button class="btn btn-indigo   btn-block w-100 mt-2" type="button"
                         onClick={()=>handleDeletePackage(item?._id)}
                         >
                          Delete
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </main>
      
      {/* edit modal */}
      <div
        class="modal"
        id="edit_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="funds_modal_modalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog  alert_modal" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Edit Phase
              </h5>
              <button
                class="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
              <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Phase </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="text"
                    onWheelCapture={e => {
                      e.target.blur()
                    }}
                    // Placeholder="Enter Minimum Amount"
                     value={editPhase}
                     onChange={(e) => setEditPhase(e.target.value)}
                  ></input>
                </div>
                
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Minimum Amount ($) </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="number"
                    onWheelCapture={e => {
                      e.target.blur()
                    }}
                    // Placeholder="Enter Minimum Amount"
                     value={minimumAmount}
                     onChange={(e) => setMinimumAmount(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Maximum Amount ($) </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="number"
                    onWheelCapture={e => {
                      e.target.blur()
                    }}
                    // Placeholder="Enter Maximum Amount"
                     value={maximumAmount}
                     onChange={(e) => setMaximumAmount(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Duration (year) </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="number"
                    onWheelCapture={e => {
                      e.target.blur()
                    }}
                    // Placeholder="Enter Duration"
                     value={time/12}
                     onChange={(e) => setTime(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Month Percentage (%) </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="number"
                    onWheelCapture={e => {
                      e.target.blur()
                    }}
                    // Placeholder="Enter month perc"
                     value={monthPer}
                     onChange={(e) => setMonthPer(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Expiry Time (days)</label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="number"
                    onWheelCapture={e => {
                      e.target.blur()
                    }}
                    // Placeholder="Enter Minimum Amount"
                     value={expiryTime}
                     onChange={(e) => setExpiryTime(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Month (Referral Commission only for direct) </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="number"
                    onWheelCapture={e => {
                      e.target.blur()
                    }}
                    // Placeholder="Enter Minimum Amount"
                     value={referralMonth}
                     onChange={(e) => setReferralMonth(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Percentage (Referral Commission only for direct) </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="number"
                    onWheelCapture={e => {
                      e.target.blur()
                    }}
                    // Placeholder="Enter Minimum Amount"
                     value={referralPer}
                    onChange={(e) => setReferralPer(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Remark (Running upto) </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="text"
                    // Placeholder="Enter Minimum Amount"
                     value={remark}
                     onChange={(e) => setRemark(e.target.value)}
                  ></input>
                </div>
                {/* <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1">Select Status</label>
                  <select
                    class="form-control  form-control-solid input-copy"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option>Select</option>
                    <option value="true">TRUE</option>
                    <option value="false">FALSE</option>
                  </select>
                </div> */}
                <div className="form-group  mb-3 position-relative">
                  <button btn btn-indigo
                    class="btn btn-indigo   btn-block w-100 mt-2"
                    type="button"
                     onClick={() =>
                       editPackage(editPhase, minimumAmount,maximumAmount,time,monthPer,referralMonth,referralPer,remark, expiryTime,id)
                     }
                  >
                    Edit
                  </button>
                  
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageManagement;
