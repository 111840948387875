import React, { useEffect, useState, updateProfile } from "react";
import { useNavigate } from "react-router-dom";
import { $ } from "react-jquery-plugin";
import AuthService from "../../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import TraderDetails from "../TraderDetails";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const TradeList = (props) => {
  const [activeScreen, setActiveScreen] = useState("userdetail");
  const [tradeList, setTradeList] = useState([]);
  const [userId, setUserId] = useState("");
  const { SearchBar } = Search;
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [exportData, setExportData] = useState([]);
  const [sendEmailOrPhone, setSendEmailOrPhone] = useState('');
  const [message, setMessage] = useState('');

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button
          className="btn btn-dark btn-sm   me-2"
          onClick={() => {
            // console.log(row, "row");
            setUserId(row?._id);
            setActiveScreen("detail");
          }}
        >
          View
        </button>
        <button class={row?.status === "Active" ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"} style={{ marginLeft: "20px" }} onClick={() => handleStatus(row?._id, row?.status === "Inactive" ? "Active" : "Inactive")}>{row?.status === "Active" ? "Active" : "Inactive"}</button>
        {row?.mobileNumber ?  <button
          className="btn btn-secondary btn-sm mt-2"
          data-bs-toggle="modal" data-bs-target="#message_modal"
          onClick={() => {
            // console.log(row, "row");
            setSendEmailOrPhone(row?.mobileNumber);
          }}
        >
          Send SMS
        </button> : undefined}
        {row?.emailId ? <button
          className="btn btn-info btn-sm mt-2"
          data-bs-toggle="modal" data-bs-target="#message_modal"
          onClick={() => {
            // console.log(row, "row");
            setSendEmailOrPhone(row?.emailId);
          }}
        >
          Send Email
        </button> : undefined}
        
        
      </div>
    );
  };

  // const linkFollow1 = (cell, row, rowIndex, formatExtraData) => {
  //   return (
  //     <>
  //       <button className="btn btn-danger btn-icon btn-sm me-2">
  //         <i className="fa fa-times"></i>
  //       </button>
  //       <button className="btn btn-success  btn-icon btn-sm">
  //         <i className="fa fa-check"></i>
  //       </button>
  //     </>
  //   );
  // };

  const linkFollow1 = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <button
          className={
            row?.withdrawalstatus === "Active"
              ? "btn btn-sm btn-success"
              : "btn btn-sm btn-danger"
          }
          style={{ marginLeft: "20px" }}
          onClick={() =>
            handleStatus(
              row?._id,
              row?.withdrawalstatus === "Active" ? "Inactive" : "Active"
            )
          }
        >
          {row?.withdrawalstatus}
        </button>
      </>
    );
  };

  const statuslinkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <div
          className={`badge ${
            row?.kycVerified === 0
              ? "bg-secondary"
              : row?.kycVerified === 1
              ? "bg-warning"
              : row?.kycVerified === 2
              ? "bg-success"
              : row?.kycVerified === 3
              ? "bg-danger"
              : undefined
          } text-white rounded-pill `}
        >
          {row?.kycVerified == "0"
            ? "Not Submited"
            : row?.kycVerified == "1"
            ? "Pending for Approval"
            : row?.kycVerified == "2"
            ? "Approved"
            : row?.kycVerified == "3"
            ? "Rejected"
            : undefined}
        </div>
      </>
    );
  };

  const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {row?.firstName} {row?.lastName}
      </>
    );
  };

  const dateFilter = (cell, row, rowIndex, formatExtraData) => {
    return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
  };

  const columns = [
    { dataField: "_id", text: "User ID" },
    { dataField: "Name", text: "Name", sort: true, formatter: nameFormatter },
    { dataField: "emailId", text: "Email", sort: true },
    { dataField: "mobileNumber", text: "Phone Number", sort: true },
    {
      dataField: "createdAt",
      text: "Registration Date",
      sort: true,
      formatter: dateFilter,
    },
    // {
    //   dataField: "status",
    //   text: "Kyc Status",
    //   sort: true,
    //   formatter: statuslinkFollow,
    // },
    { dataField: "Action", text: "Action", formatter: linkFollow },
    // {
    //   dataField: "withdrawalstatus",
    //   text: "Withdrawal Status",
    //   formatter: linkFollow1,
    // },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  useEffect(() => {
    handleExportData();
  }, []);

  const handleStatus = async (_id, cell) => {
    await AuthService.TradersStatus(_id, cell).then(
      async (result) => {
        if (result.success) {
          try {
            handleExportData();
            alertSuccessMessage(result.message);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleData = async () => {
    LoaderHelper.loaderStatus(true)
    await AuthService.getusers().then(async (result) => {
      if (result.data.length > 0) {
        try {
          LoaderHelper.loaderStatus(false);
          setExportData(result?.data.reverse());
          setDateFrom("");
          setDateTo("");
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleSendMessage = async (sendEmailOrPhone, message) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.sendMessage(sendEmailOrPhone, message).then(async (result) => {
      console.log(result, 'handleSendMessage');
      if (result.success) {
        try {
          LoaderHelper.loaderStatus(false);
          alertSuccessMessage(result.message);
          $("#message_modal").modal("hide");
          setMessage('');
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "dateFrom":
        setDateFrom(event.target.value);
        break;
      case "dateTo":
        setDateTo(event.target.value);
        break;
      default:
    }
  };

  const handleExportData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getExportList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setExportData(result?.data.reverse());
        } catch (error) {
          alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");
        console.log(result, "query");
      }
    });
  };

  return activeScreen === "userdetail" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Traders List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="filter_bar">
            <form className="row">
              <div class="mb-3 col ">
                <input
                  type="date"
                  class="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateFrom"
                  value={dateFrom}
                  onChange={handleInputChange}
                />
              </div>
              <div class="mb-3 col ">
                <input
                  type="date"
                  class="form-control form-control-solid"
                  data-provide="datepicker"
                  id="litepickerRangePlugin"
                  name="dateTo"
                  value={dateTo}
                  onChange={handleInputChange}
                />
              </div>
              <div class="mb-3 col ">
                <div className="row">
                  <div className="col">
                    <button
                      class="btn btn-indigo btn-block w-100"
                      type="button"
                      onClick={() => handleExportData()}
                      disabled={!dateFrom || !dateTo}
                    >
                      Search
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-indigo btn-block w-100"
                      type="button"
                      onClick={handleData}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card mb-4">
            <div className="card-header">
              Traders Details
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-sm dropdown-toggle"
                  id="dropdownFadeInUp"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div
                  className="dropdown-menu animated--fade-in-up"
                  aria-labelledby="dropdownFadeInUp"
                >
                  <CSVLink data={exportData} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <ToolkitProvider
                  hover
                  bootstrap4
                  keyField="_id"
                  columns={columns}
                  data={exportData}
                  exportCSV
                  search
                >
                  {(props) => (
                    <React.Fragment>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        hover
                        bootstrap4
                        keyField="_id"
                        columns={columns}
                        data={exportData}
                        pagination={pagination}
                        filter={filterFactory()}
                        {...props.baseProps}
                      />
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div
        class="modal"
        id="message_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered alert_modal"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Send Message{" "}
                <b>
                  {/* {firstName} {lastName} */}
                </b>{" "}
                
              </h5>
              <button
                class="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group mb-4 ">
                  <label className="small mb-1"> Type your Message Here: </label>
                  <textarea
                    className="form-control form-control-solid"
                    rows="7"
                    placeholder="Enter your message.."
                    value={message}
                    name="rejectReason"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <button
                  class="btn btn-danger btn-block w-100"
                  type="button"
                  disabled={!message}
                  onClick={() => {
                    handleSendMessage(sendEmailOrPhone, message)
                  }}
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <TraderDetails userId={userId} />
  );
};

export default TradeList;
