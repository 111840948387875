import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";

const StakingDetails = () => {
    const { SearchBar } = Search;
    const [stakingDetails, setStakingDetails] = useState([]);

    const dateFilter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                 {moment(row?.createdAt).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
            </>
        );
    };

    const amountFilter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                 {"$" + row?.usdtAmount}
            </>
        );
    };

    const rateFilter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                 { row?.month_percentage + "%"}
            </>
        );
    };

    const timeFilter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                  {row?.month_time % 12 === 0 &&  row?.month_time/12 + "year"}
            </>
        );
    };

    const columns = [
        { dataField: 'user_id', text: 'User Id', sort: true,  },
        { dataField: 'createdAt', text: 'Staked Date', sort: true, formatter: dateFilter },
        { dataField: 'stacking_price', text: 'Staked PLR', sort: true },
        { dataField: 'usdtAmount', text: 'Amount', sort: true , formatter: amountFilter },
        { dataField: 'month_percentage', text: 'Reward Rate', sort: true , formatter: rateFilter },
        { dataField: 'month_time', text: 'Duration', sort: true, formatter: timeFilter },
        { dataField: 'phase_name', text: 'Module', sort: true },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });

    useEffect(() => {
         handleStakingDetails()
    }, []);

    const handleStakingDetails = async () => {
        LoaderHelper.loaderStatus(true)
        await AuthService.getstakingDetails().then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false)
                try {
                    setStakingDetails(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false)
                alertErrorMessage(result.message);
            }
        });
    }

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="far fa-user"></i></div>
                                            Staking List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* Main page content */}
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div class="card-header">Staking Details
                                {stakingDetails.length === 0 ? "" :
                                    <div class="dropdown">
                                        <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export </button>
                                        <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                            <CSVLink data={stakingDetails} class="dropdown-item">Export as CSV</CSVLink>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card-body mt-3">
                                <table className="" width="100%" >
                                    {stakingDetails.length === 0 ? <h6 className="ifnoData"><img src="assets/img/no-data.png" /> <br /> No Data Available</h6> :
                                        <ToolkitProvider
                                            hover
                                            bootstrap4
                                            keyField='id'
                                            columns={columns}
                                            data={stakingDetails}
                                            exportCSV
                                            search >
                                            {
                                                props => (
                                                    <React.Fragment>
                                                        <SearchBar {...props.searchProps} />
                                                        <BootstrapTable
                                                            hover
                                                            bootstrap4
                                                            keyField='id'
                                                            columns={columns}
                                                            data={stakingDetails}
                                                            pagination={pagination}
                                                            filter={filterFactory()}
                                                            {...props.baseProps}
                                                        />
                                                    </React.Fragment>
                                                )
                                            }
                                        </ToolkitProvider>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>

    )
}

export default StakingDetails;