import React, { useState, useEffect } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const CurrencyManagement = () => {
  const [coinList, setCoinList] = useState([]);
  const [coinImage, setcoinImage] = useState();
  const [categoryList, setcategoryList] = useState([]);

  const [inputData, setinputData] = useState({});

  const handleInputChange = (event, id) => {
    if (id != inputData.id) {
      setinputData({ id: '', })
    }
    const { name, value } = event.target;
    setinputData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      id: id
    }));

  };
  useEffect(() => {
    handleCoinList();
    CoinCategory();
  }, []);

  const handleCoinList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCoinList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setCoinList(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const CoinCategory = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.CoinCategory().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setcategoryList(result.data);
        } catch (error) {
          console.log(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleAddCoinWidthraw = async (inputData, coinImage, item) => {
    LoaderHelper.loaderStatus(true);
    var formData = new FormData();
    formData.append('coin-image', coinImage);
    formData.append('_id', inputData?.id ? inputData?.id : item?._id);
    formData.append('maker_fee', inputData?.maker_fee ? inputData?.maker_fee : item?.maker_fee);
    formData.append('taker_fee', inputData?.taker_fee ? inputData?.taker_fee : item?.taker_fee);
    formData.append('min_withdrawal', inputData?.minWidthraw ? inputData?.minWidthraw : item?.min_withdrawal);
    formData.append('max_withdrawal', inputData?.max_withdraw ? inputData?.max_withdraw : item?.max_withdrawal);
    formData.append('min_deposit', inputData?.min_deposit ? inputData?.min_deposit : item?.min_deposit);
    formData.append('max_deposit', inputData?.max_deposit ? inputData?.max_deposit : item?.max_deposit);
    formData.append('transaction_fee', inputData?.trans_fee ? inputData?.trans_fee : item?.transaction_fee);
    formData.append('withdrawal_fee', inputData?.widthrawFee ? inputData?.widthrawFee : item?.withdrawal_fee);
    formData.append('total_supply', inputData?.total_supply ? inputData?.total_supply : item?.total_supply);
    formData.append('tds', inputData?.tds ? inputData?.tds : item?.tds);
    formData.append('category', inputData?.Category ? inputData?.Category : item?.category);
    await AuthService.addCoinWidthraw(formData).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setinputData({});
          setcoinImage()
          alertSuccessMessage(result.message);
          handleCoinList();
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-dollar-sign"></i>
                    </div>
                    Currency Management
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          {coinList.length === 0 ? (
            <h6 className="ifnoData">
              <img alt="" src="assets/img/no-data.png" />
              <br />
              No Data Available
            </h6>
          ) : (
            <div className="row">
              {coinList?.map((item, index) => (
                <div className="col-xl-4 mb-4">
                  <div className="card h-100">
                    <div className="card-body d-flex justify-content-center flex-column p-5 ">
                      <div className="d-flex align-items-center justify-content-start mb-4 ">
                        <div className="me-3">
                          <span class="symbol-label">
                            <img src={ApiConfig?.uploadcurrency + item?.icon_path} class="h-75 align-self-center img-fluid" alt="" />
                          </span>
                        </div>
                        <h5 className="mb-0">{item?.name}</h5>
                      </div>
                      <form>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">
                            Minimum Withdrawal Amount
                          </label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            value={inputData.id == item?._id ? inputData.minWidthraw : item?.min_withdrawal}
                            name="minWidthraw"
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">  Maximum Withdrawal Amount</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="max_withdraw"
                            value={inputData.id == item?._id ? inputData.max_withdraw : item?.max_withdrawal}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-3">
                          <label class="small mb-1">Withdrawal Fee</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="widthrawFee"
                            value={inputData.id == item?._id ? inputData.widthrawFee : item?.withdrawal_fee}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Maker Fee</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="maker_fee"
                            value={inputData.id == item?._id ? inputData.maker_fee : item?.maker_fee}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Taker Fee</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="taker_fee"
                            value={inputData.id == item?._id ? inputData.taker_fee : item?.taker_fee}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Minimum Deposit</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="min_deposit"
                            value={inputData.id == item?._id ? inputData.min_deposit : item?.min_deposit}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Maximum Deposit</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="max_deposit"
                            value={inputData.id == item?._id ? inputData.max_deposit : item?.max_deposit}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Transaction Fee</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="trans_fee"
                            value={inputData.id == item?._id ? inputData.trans_fee : item?.transaction_fee}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1"> Total Supply</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="total_supply"
                            value={inputData.id == item?._id ? inputData.total_supply : item?.total_supply}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">TDS</label>
                          <input
                            class="form-control  form-control-solid"
                            type="text"
                            name="tds"
                            value={inputData.id == item?._id ? inputData.tds : item?.tds}
                            onChange={(e) => handleInputChange(e, item?._id)}
                          ></input>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Category</label>
                          <select class="form-control  form-control-solid" type="text"
                            name="Category" value={inputData.id == item?._id ? inputData.Category : item?.category}
                            onChange={(e) => handleInputChange(e, item?._id)}>
                            {categoryList && categoryList?.map((item) => {
                              return (
                                <option value={item?.category}>
                                  {item?.category}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="form-group  mb-1">
                          <label class="small mb-1">Coin Image</label>
                          <input
                            class="form-control  form-control-solid"
                            type="file"
                            name="coin_image"
                            onChange={(e) => { setcoinImage(e.target.files[0]); inputData.id = item?._id }}
                          ></input>
                        </div>
                        <button class="btn btn-indigo   btn-block w-100 mt-2" type="button" onClick={() => handleAddCoinWidthraw(inputData, coinImage, item)}>
                          Set Limit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default CurrencyManagement;
