import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import BootstrapTable from "react-bootstrap-table-next";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory from "react-bootstrap-table2-filter";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";

const TodayDeposit = () => {

    const { SearchBar } = Search;
    const [toDayDeposit, setTodayDeposit] = useState([]);

    const linkFollowStatus = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <span className={`badge text-white rounded-pill ${row?.status === 'PENDING' ? "bg-warning" : row?.status === 'REJECTED' ? "bg-danger" : row?.status === 'CONFIRM' ? "bg-success" : ""} `}>{row?.status}</span>
            </>
        );
    };

    const linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
            <>
                <button className="btn btn-danger btn-icon btn-sm me-2" onClick={() => handleDenied(row?._id)}><i className="fa fa-times" ></i></button>
                <button className="btn btn-success  btn-icon btn-sm" onClick={() => handleApprove(row?._id)}><i className="fa fa-check" ></i></button>
            </>
        );
    };

    function imageFormatter(cell, row) {
        return (
            <a href={ApiConfig?.uploadUrl + 'help/' + row?.depositImage} target="blank">
                <img className="table-img" src={ApiConfig?.uploadUrl + 'help/' + row?.depositImage} alt=""/>
            </a>
        )
    }

    const columns = [
        { dataField: 'userName', text: 'User Name' },
        { dataField: 'emailId', text: 'Email', sort: true, },
        { dataField: 'amount', text: 'Amount', sort: true, },
        { dataField: 'transactionType', text: 'Payment Type', sort: true, },
        { dataField: 'createdAt', text: 'Date & Time', sort: true, },
        { dataField: 'utnumber', text: 'Reference Number ', sort: true, },
        { dataField: 'Image', text: 'Image', sort: true, formatter: imageFormatter },
        { dataField: 'status', text: 'Status', sort: true, formatter: linkFollowStatus },
        { dataField: 'Action', text: 'Action', sort: true, formatter: linkFollow },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: true,
    });


    useEffect(() => {
        // handleFiatWithdraw()
    }, []);

    const handleFiatWithdraw = async () => {
        await AuthService.getTodayDeposit().then(async result => {
            if (result.success) {
                try {
                    setTodayDeposit(result.data?.data2.reverse());
                } catch (error) {
                    /* alertErrorMessage(error); */
                }
            } else {
                alertErrorMessage("Something Went Wrong");
            }
        });
    }


    const handleDenied = async (userId) => {
        await AuthService.handleFiatDenied(userId).then(async result => {
            if (result.data.msg == "Rejected") {
                // alertSuccessMessage(result.data.msg);
                handleFiatWithdraw();
            } else {
                alertErrorMessage(result.data.msg)
            }
        })
    }

    const handleApprove = async (userId) => {
        await AuthService.handleFiatApprove(userId).then(async result => {
            if (result.data.msg === "Approved") {
                // alertSuccessMessage(result.data.msg);
                handleFiatWithdraw();
            } else {
                alertErrorMessage(result.data.msg)
            }
        })
    }

    return (

        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-feather"></i></div>
                                        Today's Deposit Request
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Main page content */}
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div class="card-header">
                            Today's Deposit Details
                            {toDayDeposit.length === 0 ? "" :
                                <div class="dropdown">
                                    <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Export
                                    </button>
                                    <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <CSVLink data={toDayDeposit} class="dropdown-item">Export as CSV</CSVLink>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="card-body mt-3">
                            <table /* className="table table-bordered" */ width="100%">
                                {
                                    toDayDeposit.length === 0 ? <h6 className="ifnoData">No Deposit Request
                                    Today</h6> :
                                        <ToolkitProvider
                                            hover
                                            bootstrap4
                                            keyField='_id'
                                            columns={columns}
                                            data={toDayDeposit}
                                            exportCSV
                                            search={{
                                                afterSearch: (newResult) => console.log(newResult)
                                            }}
                                        >
                                            {
                                                props => (
                                                    <React.Fragment>
                                                        <SearchBar {...props.searchProps} />
                                                        <BootstrapTable
                                                            hover
                                                            bootstrap4
                                                            keyField='_id'
                                                            columns={columns}
                                                            data={toDayDeposit}
                                                            pagination={pagination}
                                                            filter={filterFactory()}
                                                            {...props.baseProps}
                                                        />
                                                    </React.Fragment>
                                                )
                                            }
                                        </ToolkitProvider>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default TodayDeposit;




