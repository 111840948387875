import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Loginpage from "../ui/pages/LoginPage";
import ForgetpasswordPage from "../ui/pages/ForgetpasswordPage";
import DashboardPage from "../ui/pages/DashboardPage";
import { ProfileContext } from "../context/ProfileProvider";

const Routing = () => {
    const token = sessionStorage.getItem('token');
    const [profileState] = useContext(ProfileContext);

    return (
        <Router>
            <Routes>
                {token ?

                    <>
                        <Route exact path="/dashboard" element={<DashboardPage />} />
                        <Route exect path="/*" element={<DashboardPage />}></Route>
                    </>
                    :
                    <>
                        <Route exect path="/" element={<Loginpage />}></Route>
                        <Route exect path="/*" element={<Loginpage />}></Route>
                        <Route exect path="/forgotpassword" element={<ForgetpasswordPage />}></Route>
                    </>

                }


            </Routes>
        </Router>
    );
}

export default Routing;