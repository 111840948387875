import {useState} from "react";
import {alertErrorMessage, alertSuccessMessage} from "../../../customComponent/CustomAlertMessage"
import { $ } from "react-jquery-plugin";
import AuthService from "../../../api/services/AuthService"
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper"

export const Security = () =>{

  const userId = sessionStorage.getItem("userId")
  const twoFactor = sessionStorage.getItem("2fa")

const [checkedAuth, setCheckedAuth] = useState(twoFactor)
const [googleQr, setGoogleQr] = useState({});
const [vCode,setVcode] = useState("")
const [googleCode, setGoogleCode] = useState();


const checkType = () => {
  if (checkedAuth === "true") {
      getGoogleQr(userId);
  } else {
    $("#email_otp").modal('show');
    
  }
};

let type;

const getGoogleQr = async (userId) => {
  LoaderHelper.loaderStatus(true);
  await AuthService.googleAuth(userId).then(async result => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
          try {
              setGoogleQr(result.data.qr_code);
              setGoogleCode(result.data.secret.base32)
              $("#authenticator_app").modal('show');
          } catch (error) {
              alertErrorMessage(error);
          }
      } else {
          alertErrorMessage(result.message);
      }
  });
};

  // ********* Show/Hide Steps for Google Authentication  ********** //
  function hideStep() {
    const pop = document.querySelector(".step_1");
    pop.classList.add("d-none");
    const pop2 = document.querySelector(".step_2");
    pop2.classList.remove("d-none");
};

const Update2Fa = async (userId, vCode, type) => {
  console.log(type, "tt")
  LoaderHelper.loaderStatus(true);
  await AuthService.update2fa(userId, vCode, type).then(async result => {
      LoaderHelper.loaderStatus(false);
      if (result.success) {
          try {
            sessionStorage.setItem("2fa", type)
              $("#authenticator_app").modal('hide');
              $("#otp").modal('hide');
              $("#email_otp").modal('hide');
              alertSuccessMessage(result.message)
          } catch (error) {
              alertErrorMessage(error);
              console.log('error', `${error}`);
          }
      } else {
          alertErrorMessage(result.message);
      }
  });
}


    return (
        <>
          <div id="layoutSidenav_content">
            <main>
              <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                <div className="container-xl px-4">
                  <div className="page-header-content pt-4">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-auto mt-4">
                        <h1 className="page-header-title">
                          <div className="page-header-icon">
                            <i className="fa fa-wallet"></i>
                          </div>
                          Security
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              {/* Main page content */}
              <div className="container-xl px-4 mt-n10">
                  <div className="card mb-4"> 

                  <div className="card-body" >
                    <div class=" py-3 text-center ">
                      <h4 class="mb-1"> <strong>Two Factor Authentication</strong></h4>
                      <p class="formate mb-0">Select Two Factor Authentication for your security.</p>
                    </div>


                    <div className="row justify-content-center my-3" >
                      <div className="col-lg-8" >
                          <div class="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-4">
                            <div class="d-flex align-items-center justify-space-between">
                              <h6 class="mb-0 w-100" data-bs-toggle="modal" data-bs-target="#authenticator_app" >Authenticator App</h6>
                              <div class="form-check  switch_btns form-switch">
                                <input class="form-check-input" type="checkbox" name="Two-Factor" id="1" onChange ={() => setCheckedAuth("true")} checked={checkedAuth === "true"} />
                              </div>
                            </div>
                            <hr/>
                            
                            <div class="d-flex align-items-center justify-space-between">
                              <h6 class="mb-0 w-100">None</h6>
                              <div class="form-check  switch_btns form-switch">
                                <input class="form-check-input" type="checkbox" name="Two-Factor" id="2" onChange={() => setCheckedAuth("false")} checked={checkedAuth === "false"} />
                              </div>
                            </div>
                            <hr/>
                            <div class="col-md-12 mb-1 mt-5 ">
                              <div class="field-box text-center">
                                <button class="btn btn-indigo w-md-50  justify-content-center btn-medium mb-0" type="button" data-toggle="modal" disabled={twoFactor == checkedAuth} onClick={() => checkType()}><span>Submit</span></button>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>



                  </div>


                  </div>
              </div>
    
             
             
            </main>
          </div>


          {/* <!-- Modal google Authenticator App  --> */}
          <div class="modal fade " id="authenticator_app" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog alert_modal ">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Authenticator App </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body  "> 
                   <div className="row justify-content-center" >
                      <div className="col-lg-9" >
                          {/* step 1 */}
                          <div className="step_1" >
                            <img src={googleQr}
                            className="img-fluid qr_img"   />
                            <div className="form-group text-center" >
                                <input type="text" className="form-control" value={googleCode}  readonly="readonly"  onClick={() => {
                          navigator.clipboard.writeText(googleCode);
                          alertSuccessMessage("Copied!!");
                        }}  /> 
                                <small  className="mt-2 d-block" > Click above to copy </small>
                            </div>
                            <button type="button" class="btn btn-indigo w-100 mt-4 mb-2" onClick={() => hideStep()}> Next </button>
                          </div>

                          {/* step 2 */}
                          <div className="step_2 d-none" > 
                            <div className="form-group " >
                                <label className="label" > Enter Code </label>
                                <input type="text" className="form-control" placeholder="Enter Code here..." value={vCode} onChange={(event) => setVcode(event.target.value)} />  
                            </div>
                            <button type="button" onClick={() => Update2Fa(userId, vCode, checkedAuth)} class="btn btn-indigo w-100 mt-4 mb-2"> Submit  </button>
                          </div>

                      </div>
                   </div> 
                   
                </div> 
              </div>
            </div>
          </div>

           {/* <!-- Modal email OTP --> */}
           <div class="modal fade " id="email_otp" tabindex="-1" aria-labelledby="email_otpLabel" aria-hidden="true">
            <div class="modal-dialog alert_modal ">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="email_otpLabel">Code is sent to google authenticator app </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body  "> 
                  <div className="row justify-content-center" >
                      <div className="col-lg-9" >  
                          <div className="" > 
                            <div className="form-group " >
                                <label className="label" > Enter Code </label>
                                <input type="text" className="form-control" placeholder="Enter Code here..." value={vCode} onChange={(event) => setVcode(event.target.value)} />  
                            </div>
                            <button type="button" class="btn btn-indigo w-100 mt-4 mb-2" onClick={() => Update2Fa(userId, vCode, checkedAuth)}> Submit  </button>
                          </div>

                      </div>
                  </div> 
                  
                </div> 
              </div>
            </div>
          </div>


        </>
      );
}