import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import { ProfileContext } from "../../../context/ProfileProvider";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const LoginPage = () => {
    const navigate = useNavigate();
    const [, updateProfileState] = useContext(ProfileContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isAuth,setIsAuth] = useState(false)
    const [vCode,setVCode] = useState()

    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "email":
                setEmail(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            default:
        }
    }

    const handleLogin = async (email, password) => {
        // navigate('/dashboard');

        LoaderHelper.loaderStatus(true);
        await AuthService.login(email, password).then(async result => {
            if (result.success){
                if (!result?.data?.['2fa']) {
                    LoaderHelper.loaderStatus(false);
                    try {
                        sessionStorage.setItem("token", result.data.token);
                        sessionStorage.setItem("emailId", result.data.email_or_phone);
                        sessionStorage.setItem("userType", result.data.admin_type);
                        sessionStorage.setItem("userId", result.data.id);
                        sessionStorage.setItem("2fa", result?.data?.['2fa'])
                        sessionStorage.setItem("permissions", JSON.stringify(result?.data?.permissions || []));
                        updateProfileState(result.data);
                        alertSuccessMessage(result.message);
                        navigate('/dashboard');
                    } catch (error) {
                        alertErrorMessage(error);
                    }
                } else {
                    setIsAuth(true)
                    LoaderHelper.loaderStatus(false);
                    
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
            
        });
    }

    const handleAuthVerify = async (
        vCode,
        email
      ) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getCode(
            vCode,
            email        
        ).then(async (result) => {
          if (result.success) {
            LoaderHelper.loaderStatus(false);
            try {
                sessionStorage.setItem("token", result.data.token);
                sessionStorage.setItem("emailId", result.data.email_or_phone);
                sessionStorage.setItem("userType", result.data.admin_type);
                sessionStorage.setItem("userId", result.data.id);
                sessionStorage.setItem("2fa", result?.data?.['2fa'])
                sessionStorage.setItem("permissions", JSON.stringify(result?.data?.permissions || []));
                updateProfileState(result.data);
              alertSuccessMessage('Login Successfull!!');
              navigate("/dashboard");
            } catch (error) {
              LoaderHelper.loaderStatus(false);
              alertErrorMessage(error);
    
              //console.log('error', `${error}`);
            }
          } else {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(result.message);
          }
        });
      };

    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main>
                  <div className="login-card " >
                      <div className="container-xl px-4">
                          <div className="row justify-content-center">
                              <div className={`col-xl-5 col-lg-6 col-md-8 col-sm-11 ${isAuth && "d-none"}`}>
                                
                                  <div className="card ">
                                    <div className="card-body p-3 p-md-5 pb-0 pb-md-4 text-center">
                                        <img src="assets/img/logo-dark.png" className="img-fluid" alt="" width='160' />
                                    </div>
                                      
                                      {/* <hr className="my-0" /> */}
                                      <div className="card-body p-3 p-md-5 pt-md-0">
                                          <h2 class="text-center"><strong>Sign in to account</strong></h2>
                                          <p class="text-center"><small>Enter your email &amp; password to login</small></p>
                                          <form className="mt-4" >
                                              <div className="mb-3">
                                                  <label className="text-gray-600 small" for="emailExample">Email address</label>
                                                  <input className="form-control form-control-solid" type="email" name="email" placeholder="" aria-label="Email Address" aria-describedby="emailExample" value={email} onChange={handleInputChange} />
                                              </div>
                                              <div className="mb-3">
                                                  <label className="text-gray-600 small" for="passwordExample">Password</label>
                                                  <input className="form-control form-control-solid" type="password" placeholder="" aria-label="Password" name="password" aria-describedby="passwordExample" value={password} onChange={handleInputChange} />
                                              </div>
                                              <div className="text-end" >
                                                  <Link className=" text-decoration-none small text-dark" to="/forgotpassword">Forgot your password?</Link>
                                              </div>
                                              <div className="text-center py-3 mt-2">
                                                  <button type="button" className="btn btn-block w-100 btn-xl btn-warning btn_admin mt-2 py-2 px-5"
                                                      onClick={() => handleLogin(email, password)}>
                                                      <b>Login</b>
                                                  </button>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>

                              <div className={`col-xl-5 col-lg-6 col-md-8 col-sm-11 otb_tab  ${!isAuth && "d-none"} `}>
                              <div className="card" >
                                <div className="card-body p-3 p-md-5 ">
                                        <h2 class="text-center"><strong>Verification</strong></h2>
                                        <p class="text-center"><small> Your Code will be sent to Google Authenticator App </small></p>
                                
                                <form className="mt-4" >
                                  <div className="row">
                                    <div className="col-md-12 mb-4">
                                      <div className="form-group">
                                        <label for="Code" className="form-label">
                                          Enter Code
                                        </label>
                                        <input
                                          className="form-control"
                                          id="Verification"
                                          type="text"
                                          value={vCode}
                                          placeholder="Enter Code.."
                                          onChange={(e) => setVCode(e.target.value)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 ">
                                    <div
                                        className="field-box text-end "
                                        style={{ cursor: "pointer" }}
                                         onClick={() => handleLogin(email, password)}
                                      >
                                        Re-send verification code
                                        {/* </Link> */}
                                      </div> 
                                    </div>
                                    <div className="col-md-12 ">
                                      <div className="text-center py-3 mt-2">
                                        <button
                                          className="btn btn-block w-100 btn-xl btn-warning btn_admin mt-2 py-2 px-5"
                                          type="button"
                                          onClick={() =>
                                            handleAuthVerify(
                                              vCode,
                                              email
                                            )
                                          }
                                        >
                                          <b>Verify</b>
                                        </button>
                                      </div>
                                    </div>

                                    
                                <div className="col-lg-12">
                                  <div className="small text-center mt-2">
                                    Back to{" "}
                                    <Link
                                      to="#"
                                      className={"  text-decoration-none text-black"}
                                      onClick={() => setIsAuth(false)}
                                    >
                                      <b>Login</b>
                                    </Link>
                                  </div>
                                </div>


                                  </div>
                                </form>


                                </div> 
                              </div>              

                              </div>
                          </div>
                      </div>
                  </div>
                </main>
            </div>
        </div>
    );
}

export default LoginPage;