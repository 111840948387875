import React, { useState, useEffect } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { CSVLink } from "react-csv";
import { $ } from "react-jquery-plugin";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { Search, } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const AddPackage = () => {
  const [minimumAmount, setMinimumAmount] = useState()
  const [maximumAmount, setMaximumAmount] = useState()
  const [time, setTime] = useState()
  const [monthPer, setMonthPer] = useState()
  const [expiryTime, setExpiryTime] = useState()
  const [referralMonth, setReferralMonth] = useState()
  const [referralPer, setReferralPer] = useState()
  const [remark, setRemark] = useState()
  const [status, setStatus] = useState("true")
  const [phase, setPhase] = useState("")
  const [ statusPhase ,setStatusPhase] = useState([])
  const [ statusPh ,setStatusPh] = useState()


  

  // *******Get Values of All Inputs ************//
  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "phase":
        setPhase(event.target.value);
        break;
      case "minimumAmount":
        setMinimumAmount(event.target.value);
        break;
        break;
      case "maximumAmount":
        setMaximumAmount(event.target.value)
        break;
      case "duration":
        setTime(event.target.value);
        break;
      case "monthPercentage":
        setMonthPer(event.target.value);
        break;
      case "expiryTime":
        setExpiryTime(event.target.value);
        break;
      case "referralMonth":
        setReferralMonth(event.target.value);
        break;
      case "referralPercentage":
        setReferralPer(event.target.value);
        break;
        case "remark":
        setRemark(event.target.value);
        break;
        case "status":
        setStatus(event.target.value);
        break;
      default:
    }
  };

  const resetInputChange = () => {
    setPhase("");
    setMinimumAmount("");
    setMaximumAmount("");
    setTime("")
    setMonthPer("")
    setExpiryTime("");
    setReferralMonth("")
    setReferralPer("");
    setRemark("");
    setStatus("true")

}

useEffect(()=>{
  handlePhasesList()
},[])

 
  const handleAddPackage = async (phase,minimumAmount,maximumAmount,time,monthPer,expiryTime,referralMonth,referralPer,remark,status) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.AddPackage(phase,minimumAmount,maximumAmount,time,monthPer,expiryTime,referralMonth,referralPer,remark,status).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          resetInputChange()
          handlePhasesList()
           alertSuccessMessage(result.message)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };
  
  const handlePhasesList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.phaseList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setStatusPhase(result.data);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const handleStatus= async (statusPh) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.updateStatus(statusPh).then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
           alertSuccessMessage(result.message)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  

  



  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      {/* <div className="page-header-icon">
                        <i className="fa fa-prescription"></i>
                      </div> */}
                      Add Package
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="row">
              <div className="col-xl-4">
                <div class="card mb-4">
                  <div className="card-body d-flex justify-content-center flex-column p-4 ">
                    <div className="d-flex align-items-center justify-content-start mb-3 ">
                      <h5 className="mb-0">Add Package</h5>
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Phase
                      </label>
                      <input
                        className="form-control"
                        placeholder="Phase"
                        type="number"
                        onWheelCapture={e => {
                          e.target.blur()
                        }}
                        name="phase"
                        value=  {phase}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Minimum Amount ($)
                      </label>
                      <input
                        className="form-control"
                        placeholder="Minimum Amount"
                        type="number"
                        onWheelCapture={e => {
                          e.target.blur()
                        }}
                        name="minimumAmount"
                        value={minimumAmount}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Maximum Amount ($)
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        onWheelCapture={e => {
                          e.target.blur()
                        }}
                        placeholder="Maximum Amount"
                        name="maximumAmount"
                        value= {maximumAmount}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Duration (year)
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        onWheelCapture={e => {
                          e.target.blur()
                        }}
                        placeholder="Duration"
                        name="duration"
                        value={time}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Month Percentage (%)
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        onWheelCapture={e => {
                          e.target.blur()
                        }}
                        placeholder="Month Percentage"
                        name="monthPercentage"
                        value={monthPer}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Expiry Time (days)
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        onWheelCapture={e => {
                          e.target.blur()
                        }}
                        placeholder=" Expiry Time"
                        name="expiryTime"
                        value={expiryTime}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Month(Referral Commission only for direct)
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        onWheelCapture={e => {
                          e.target.blur()
                        }}
                        placeholder="Referral Month"
                        name="referralMonth"
                        value={referralMonth}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Percentage(Referral Commission only for direct)
                      </label>
                      <input
                        className="form-control"
                        placeholder="Referral Percentage"
                        type="number"
                        onWheelCapture={e => {
                          e.target.blur()
                        }}
                        value={referralPer}
                        name="referralPercentage"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Remark (running upto)
                      </label>
                      <input
                        className="form-control"
                        placeholder="Remark"
                        type="text"
                        value={remark}
                        name="remark"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Status
                      </label>
                      <select class="form-control  form-control-solid" type="text"
                        name="status"
                        onChange={handleInputChange}>
                            <option value="true">TRUE</option>
                            <option value="false">FALSE</option>
                      </select>
                    </div>
                    <div className="form-group ">
                      <button className="btn btn-indigo w-100" type="button"
                       onClick={() => handleAddPackage(phase,minimumAmount,maximumAmount,time,monthPer,expiryTime,referralMonth,referralPer,remark,status)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8">
                <div class="card mb-4">
                  <div className="card-body d-flex justify-content-center flex-column p-4 ">
                    <div className="d-flex align-items-center justify-content-start mb-4 ">
                      <h5 className="mb-0">Update Status</h5>
                    </div>
                    <div className="form-group mb-3">
                      <label class="small mb-1">
                        Status
                      </label>
                      <select class="form-control  form-control-solid" type="text"
                        name="status"
                        value={statusPh}
                        onChange={(e)=>setStatusPh(e.target.value)}>
                          <option>Select Phase to make it Visible</option>
                          {statusPhase.map(item=>(
                            <option value={item}>{item}</option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group ">
                      <button className="btn btn-indigo w-100" type="button"
                       onClick={() => handleStatus(statusPh)}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}

          
           
        </main>
      </div>

      
    </>
  );
};

export default AddPackage;
